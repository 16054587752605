import React, { FC, useState } from 'react';
import { useParams } from '../../../utilities/routing';
import { Timesheet } from '../../Timesheet';
import { StandaloneWrapper } from '../../../utilities/wrappers';
import { View } from 'react-native';
import QRCode from 'react-qr-code';
import { CIcon, CText } from '../../../components';
import { urlHandlerMessages } from '../urlHandler.messages';
import { useStyle, useTheme } from '../../../utilities/styles';

export const FillTimesheet: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const { timesheetId } =
        useParams<{ timesheetId: string; contractId: string }>();

    const [complete, setComplete] = useState(false);
    const [url, setUrl] = useState<string>();
    /**
     * render
     */
    return (
        <StandaloneWrapper>
            {!complete && (
                <Timesheet
                    embedded
                    signOff
                    timesheetId={timesheetId}
                    onComplete={(url) => {
                        setComplete(true);
                        setUrl(url);
                    }}
                />
            )}
            {!!complete && (
                <View style={[style.centeredItems]}>
                    {!!url && (
                        <View style={style.verticalPadded}>
                            <QRCode value={url} size={150} />
                        </View>
                    )}
                    {!url && (
                        <View style={style.verticalPadded}>
                            <CIcon
                                icon="check"
                                tint={theme.successColor}
                                size={40}
                            />
                        </View>
                    )}
                    <CText message={urlHandlerMessages.timesheetSuccess} />
                </View>
            )}
        </StandaloneWrapper>
    );
};
