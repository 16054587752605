import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import { useFireBase } from '../../utilities/firebase';
import { useStyle, useTheme } from '../../utilities/styles';
import { CButton, CIcon, CText } from '../elements';
import { Spinner } from '../Spinner/Spinner';

import { IPDFViewerProps } from './props';

import './PDFViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFViewer: FC<IPDFViewerProps> = ({
    filename,
    additionalButtons,
    onReachedLastPage,
    issuedOn,
}) => {
    const style = useStyle();
    const { theme } = useTheme();
    const [data, setData] = useState<any>();
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState(1);
    // const [documentHeight, setDocumentHeight] = useState(0);
    const { userData, getFile } = useFireBase();

    useEffect(() => {
        getFile(filename).then((res) => {
            setData(res);
            setPageNumber(1);
        });
    }, [filename, issuedOn]);

    useEffect(() => {
        if (onReachedLastPage && numPages) {
            if (numPages === pageNumber) {
                onReachedLastPage();
            }
        }
    }, [onReachedLastPage, numPages, pageNumber]);

    return (
        <View style={{ alignItems: 'center', width: '100%' }}>
            {data && (
                <View
                    style={[
                        style.horizontal,
                        {
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            zIndex: 1000,
                        },
                    ]}
                >
                    <CButton
                        style={{}}
                        onPress={() => {
                            // @ts-ignore
                            const blob = new Blob([data], {
                                type: 'application/pdf',
                            });
                            // @ts-ignore
                            window.open(URL.createObjectURL(blob));
                        }}
                        icon="download"
                        minor
                    />
                </View>
            )}
            <Document
                file={data}
                onLoadSuccess={(sE) => {
                    setNumPages(sE.numPages);
                    // sE.getPage(1).then((p) =>
                    //     setDocumentHeight(p.getViewport().height),
                    // );
                }}
                onLoadError={(e: any) => {
                    console.error(e);
                }}
                error={() => (
                    <View
                        style={[
                            style.card,
                            { paddingVertical: 50 },
                            style.centeredItems,
                        ]}
                    >
                        <CIcon
                            icon={'close'}
                            tint={theme.errorColor}
                            size={150}
                        />
                        <CText message={'Error loading PDF'} />
                    </View>
                )}
                loading={<Spinner />}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            {pageNumber > 0 && (
                <View
                    style={{
                        marginTop: -50,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingHorizontal: 20,
                    }}
                >
                    <CButton
                        cy={'prevpage'}
                        icon="chevronLeft"
                        onPress={() => {
                            setPageNumber(pageNumber - 1);
                        }}
                        minor
                        disabled={pageNumber <= 1}
                    />
                    <CText>
                        {pageNumber} / {numPages}
                    </CText>
                    <CButton
                        cy={'nextpage'}
                        icon="chevronRight"
                        onPress={() => {
                            setPageNumber(pageNumber + 1);
                        }}
                        minor
                        disabled={numPages <= pageNumber}
                    />
                </View>
            )}
            {data && additionalButtons && (
                <View
                    style={[
                        {
                            width: '100%',
                        },
                        style.horizontalSpaced,
                        style.verticalPadded,
                    ]}
                >
                    {additionalButtons.map((b, index) => (
                        <CButton
                            cy={b.cy}
                            key={index}
                            icon={b.icon}
                            onPress={b.onPress}
                            title={b.title}
                            minor
                            style={{ borderColor: b.color }}
                            fontStyles={{ color: b.color }}
                            iconColor={b.color}
                            disableOnClick={b.disableOnClick}
                        />
                    ))}
                </View>
            )}
        </View>
    );
};
