import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { EFile, EGeneralFiles } from '../../enums';
import { MFile } from '../../models';
import { useDialog } from '../../utilities/dialog';
import { useLock, useFileUpload } from '../../utilities/hooks';
import {
    generalMessages,
    actionMessages,
    filenameMessages,
} from '../../utilities/messages';
import { useStyle } from '../../utilities/styles';
import { DocumentPreviewRow } from '../DocumentRow';
import { FilePicker } from '../FilePicker';
import { workplaceMessages } from '../../container/Workplace/workplace.messages';
import { useFireBase } from '../../utilities/firebase';
import { fileInputMessages } from './fileInput.messages';
import { CImage, CText } from '..';

export const FileInput: FC<{
    collection: string;
    directory: string;
    fileTypes: EFile[];
    defaultType: EFile;
    legacyFiles?: MFile[];
    clearLegacyFiles?: () => void;
}> = ({
    directory,
    collection,
    fileTypes,
    defaultType,
    legacyFiles,
    clearLegacyFiles,
}) => {
    const style = useStyle();
    const { lock } = useLock();
    const dialog = useDialog();
    const fileUpload = useFileUpload();
    const { post, put, remove, getDataIndex } = useFireBase();
    // local state
    const [files, setFiles] = useState<MFile[]>([]);
    const docs = useMemo(() => fileTypes, [fileTypes]);
    const [didLoad, setDidLoad] = useState(false);
    /**
     * pre file
     */
    const preFile = useCallback(async () => {
        let nextType: EFile = defaultType || docs[0] || EGeneralFiles.other;
        if (
            await dialog({
                title: fileInputMessages.selectFileType,
                message: fileInputMessages.selectFileTypeText,
                icon: 'info',
                pickerInputs: [
                    {
                        id: 'type',
                        title: fileInputMessages.fileType,
                        placeholder: fileInputMessages.fileTypePlaceholder,
                        values: docs.map((ecf) => {
                            return {
                                label: filenameMessages[ecf],
                                value: ecf,
                            };
                        }),
                    },
                ],
                buttons: [
                    {
                        text: actionMessages.ok,
                        disabled: (inputs) =>
                            !inputs?.find((i) => i.id === 'type'),
                        onPress: (inputs) => {
                            const ioi = inputs?.find((i) => i.id === 'type');
                            if (ioi) {
                                nextType = ioi.value as EFile;
                            }
                        },
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            })
        ) {
            return { nextType };
        }
    }, [dialog, docs]);
    /**
     * callback to handle a new fileupload
     */
    const handleFile = useCallback(
        async (
            newFn: string,
            file: Uint8Array,
            preFileResult: { nextType: EFile },
        ) => {
            const unlock = lock();
            try {
                const baseFile = await fileUpload(directory, newFn, file);
                const fullFile = new MFile({
                    ...baseFile,
                    type: preFileResult.nextType,
                });
                const postResult = await post(collection, fullFile);
                if (postResult) {
                    fullFile.documentId = postResult.id;
                    setFiles((prev) => [...prev, fullFile]);
                }
                unlock();
            } catch (e) {
                unlock();
                dialog({
                    title: generalMessages.errorOccured,
                    message: `${e}`,
                    icon: 'error',
                });
            }
        },
        [collection, directory, fileUpload],
    );
    /**
     * callback to handle deletion of file
     */
    const handleDelete = useCallback(
        async (documentId: string) => {
            if (
                await dialog({
                    icon: 'question',
                    title: workplaceMessages.confirmDeleteFile,
                    message: workplaceMessages.confirmDeleteFileMessage,
                    buttons: [{ text: actionMessages.ok }],
                    cancelButton: { text: actionMessages.cancel },
                })
            )
                setFiles((prev) => {
                    const next = Array.from(prev);
                    const foi = next.find((v) => v.documentId === documentId);
                    if (foi) {
                        next.splice(next.indexOf(foi), 1);
                        remove(collection, foi.documentId);
                    }
                    return next;
                });
        },
        [collection],
    );
    /**
     * callback to handle change to singular document values
     */
    const handleChange = useCallback(
        (documentId: string, change: Partial<MFile>) => {
            setFiles((prev) => {
                const next = Array.from(prev);
                const foi = next.find((v) => v.documentId === documentId);
                if (foi) {
                    const nextFile = new MFile({ ...foi, ...change });
                    next.splice(next.indexOf(foi), 1, nextFile);
                    put(collection, documentId, nextFile);
                }
                return next;
            });
        },
        [collection],
    );
    /**
     * effect to load files
     */
    useEffect(() => {
        getDataIndex(collection).then((r) => {
            setFiles((r as MFile[]).map((v) => new MFile(v)));
            setDidLoad(true);
        });
    }, [collection]);
    /**
     * effect to move legacy files to destinct files collections
     */
    useEffect(() => {
        if (legacyFiles && legacyFiles.length && clearLegacyFiles && didLoad) {
            const p = legacyFiles.map(async (f) => {
                if (files.find((b) => f.path === b.path)) return;
                f.documentId = '';
                const postResult = await post(collection, f);
                if (postResult) {
                    f.documentId = postResult.id;
                    setFiles((prev) => [...prev, new MFile(f)]);
                }
            });
            Promise.all(p).then(clearLegacyFiles);
        }
    }, [legacyFiles, clearLegacyFiles, files, didLoad]);
    /**
     * render
     */
    return (
        <View>
            {!!files.length && (
                <View style={[style.horizontalSplit, style.verticalPadded]}>
                    <View />
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CText message={fileInputMessages.uploadAnotherFile} />
                        <FilePicker onFile={handleFile} preFile={preFile} />
                    </View>
                </View>
            )}
            {files.map((file, i) => {
                if (!docs.includes(file.type)) {
                    return;
                }
                return (
                    <DocumentPreviewRow
                        key={file.documentId}
                        file={file}
                        onDelete={() => handleDelete(file.documentId)}
                        onChangeValidUntil={(validUntil) =>
                            handleChange(file.documentId, { validUntil })
                        }
                    />
                );
            })}
            {!files.length ? (
                <View style={style.verticalHeavyPadded}>
                    <CImage image="documents" maxWidth={200} />
                    <CText
                        message={fileInputMessages.noFiles}
                        centered
                        secondaryHeadline
                    />
                    <View style={style.horizontalSpaced}>
                        <FilePicker onFile={handleFile} preFile={preFile} />
                    </View>
                </View>
            ) : (
                <View style={style.verticalHeavyPadded}>
                    <CText
                        message={fileInputMessages.uploadAnotherFile}
                        centered
                    />
                    <View style={style.horizontalSpaced}>
                        <FilePicker onFile={handleFile} preFile={preFile} />
                    </View>
                </View>
            )}
        </View>
    );
};
