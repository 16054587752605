import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    MAgency,
    MApplication,
    MJob,
    MProfessionalInfo,
} from '../../../../models';
import { LayoutChangeEvent, Linking, View } from 'react-native';
import {
    CText,
    Spinner,
    CButton,
    TouchableView,
    ProfilePicture,
    CCard,
    StarDisplay,
} from '../../../../components';
import { ApplicationPicture } from '../../../../components/ProfilePicture/ApplicationPicture';
import {
    EApplicationStatus,
    ECollections,
    EEnvironment,
    EField,
} from '../../../../enums';
import {
    actionMessages,
    educationMessages,
    generalMessages,
    positionMessages,
    specializationMessages,
} from '../../../../utilities/messages';
import { jobMessages } from '../../../Job/job.messages';
import { useFormat } from '../../../../utilities/intl';
import { useFireBase } from '../../../../utilities/firebase';
import { useStyle, useTheme } from '../../../../utilities/styles';
import { profileMessages } from '../../../Profile/Profile.messages';
import { applicationMessages } from '../../../Application/application.messages';
import { jobApplicationMessages } from '../../JobApplication.messages';
import { useNavigate } from 'react-router-native';
import { convertNumberToCurrencyString } from '../../../../utilities/functions';
import { contractMessages } from '../../../Contract/contract.messages';
import { useDialog } from '../../../../utilities/dialog';
import { useEnvironment } from '../../../../utilities/contexts';
import { useLock } from '../../../../utilities/hooks';

export const ApplicationRow: FC<{
    application: MApplication;
    job: MJob;
    handleStatusChange: (
        application: MApplication,
        nextStatus: EApplicationStatus,
    ) => void;
    cellWidth: number;
    onLayout?: (event: LayoutChangeEvent) => void;
}> = ({ application, job, handleStatusChange, cellWidth, onLayout }) => {
    // global state
    const format = useFormat();
    const { theme } = useTheme();
    const style = useStyle();
    const { region, environment } = useEnvironment();
    const { getFile, callFunction, getDataById, userWorkplaces } =
        useFireBase();
    const { lock } = useLock();
    const navigate = useNavigate();
    const dialog = useDialog();
    // local state
    const [agency, setAgency] = useState<MAgency>();
    const [profInfos, setProfInfos] = useState<MProfessionalInfo>();
    const [tempProfile, setIsTempProfile] = useState(false);
    /**
     * memoized calculated rate
     */
    const rate = useMemo(
        () => application.wage.wage + application.wage.regularWage,
        [application],
    );
    /**
     * memoized calculated cell width
     */
    const localCellWidth = useMemo(() => {
        return cellWidth > 175 ? cellWidth : 175;
    }, [cellWidth]);
    /**
     * callback to cancel negotiation related to application
     */
    const cancelContract = useCallback(() => {
        if (!application.contractId) {
            return;
        }
        let reason = '';
        dialog({
            title: contractMessages.youIssuedAClose,
            message: contractMessages.youIssuedACloseText,
            cancelButton: {
                text: actionMessages.cancel,
            },
            buttons: [
                {
                    text: actionMessages.ok,
                    onPress: (inputs) => {
                        reason =
                            inputs?.find((i) => i.id === 'reason')?.value || '';
                    },
                    disabled: (inputs) => {
                        const tip = inputs?.find((i) => i.id === 'reason');
                        if (!tip) {
                            return true;
                        } else {
                            return !tip.value.length;
                        }
                    },
                },
            ],
            textInputs: [
                {
                    id: 'reason',
                    title: contractMessages.negotiationCancellationReason,
                },
            ],
            icon: 'warning',
        }).then((ok) => {
            console.log(ok, 'yeet');
            if (ok) {
                callFunction('cancelContract', {
                    contractId: application.contractId,
                    cancelReason: reason,
                });
            }
        });
    }, [application]);
    /**
     * callback to load filename and verify access to file and state of file
     */
    const getResume = useCallback(async () => {
        const unlock = lock();
        try {
            const filename = await callFunction('getResume', {
                applicationId: application.documentId,
                environment,
            });
            const res = await getFile(filename);
            const blob = new Blob(
                // @ts-ignore
                [res],
                {
                    type: 'application/pdf',
                },
            );

            Linking.openURL(URL.createObjectURL(blob));
        } finally {
            unlock();
        }
    }, [application, environment]);
    /**
     * effect to load agency of application
     */
    useEffect(() => {
        if (
            application.agencyId &&
            userWorkplaces
                .find((wp) => wp.documentId === application.jobWorkplace)
                ?.agencies.includes(application.agencyId)
        ) {
            getDataById(ECollections.agencies, application.agencyId).then(
                (agency) => setAgency(new MAgency(agency)),
            );
        }
    }, [application]);
    /**
     * effect to load profInfos
     */
    useEffect(() => {
        if (application.profileId) {
            getDataById(
                ECollections.publicProfProfiles,
                application.profileId,
            ).then((v) => {
                setProfInfos(new MProfessionalInfo(v));
                if (v.temp) setIsTempProfile(true);
            });
        } else {
            getDataById(
                ECollections.publicUsers,
                application.applicantUid,
            ).then((v) => setProfInfos(new MProfessionalInfo(v)));
        }
    }, [application]);
    /**
     * render
     */
    return (
        <CCard onLayout={onLayout} hoverEffects>
            <View style={[style.flex1, style.horizontal]}>
                <View style={[style.flex1, style.horizontalWrap]}>
                    <View style={style.horizontalPadded}>
                        <ApplicationPicture data={application} />
                    </View>
                    <View
                        style={[
                            style.horizontalPadded,
                            { width: localCellWidth },
                        ]}
                    >
                        <CText message={jobMessages.educations} />
                        <CText
                            headline
                            message={
                                (job.employeeField === EField.general
                                    ? application.educations.filter((e) =>
                                          job.employeeEducations.includes(e),
                                      )
                                    : application.educations
                                )
                                    .map((e) =>
                                        format(
                                            educationMessages[
                                                e as keyof typeof educationMessages
                                            ],
                                        ),
                                    )
                                    .join(',\n') || '-'
                            }
                        />
                    </View>
                    <View
                        style={[
                            style.horizontalPadded,
                            { width: localCellWidth },
                        ]}
                    >
                        <CText message={jobMessages.specializations} />
                        <CText
                            headline
                            message={
                                application.specializations
                                    .map((spec) =>
                                        format(
                                            specializationMessages[
                                                spec as keyof typeof specializationMessages
                                            ],
                                        ),
                                    )
                                    .join(',\n') || '-'
                            }
                        />
                    </View>
                    <View
                        style={[
                            style.horizontalPadded,
                            { width: localCellWidth },
                        ]}
                    >
                        <CText message={jobMessages.employeePosition} />
                        <CText
                            headline
                            message={
                                positionMessages[
                                    application.position as keyof typeof positionMessages
                                ] || '-'
                            }
                        />
                    </View>
                    <View
                        style={[
                            style.horizontalPadded,
                            { width: localCellWidth },
                        ]}
                    >
                        <CText message={profileMessages.yoe} />
                        <CText
                            headline
                            message={
                                `${application.yearsOfExperience} ${format(
                                    application.yearsOfExperience !== 1
                                        ? generalMessages.years
                                        : generalMessages.year,
                                )}` || '-'
                            }
                        />
                    </View>
                    <View
                        style={[
                            style.horizontalPadded,
                            { width: localCellWidth },
                        ]}
                    >
                        <CText message={applicationMessages.period} />
                        {application.fromNow ? (
                            <CText
                                headline
                                message={
                                    applicationMessages.fromNowTilUndefined
                                }
                            />
                        ) : (
                            <CText
                                headline
                                message={`${new Date(
                                    application.from,
                                ).toLocaleDateString('de')} - ${new Date(
                                    application.to,
                                ).toLocaleDateString('de')}`}
                            />
                        )}
                    </View>
                </View>
                <View
                    style={[style.horizontalPadded, { width: localCellWidth }]}
                >
                    <CText
                        message={
                            !tempProfile
                                ? applicationMessages.rating
                                : applicationMessages.profileType
                        }
                    />
                    {profInfos ? (
                        !tempProfile ? (
                            <TouchableView
                                style={[style.horizontal, style.centeredItems]}
                                onPress={() =>
                                    navigate(
                                        `/reviews/${
                                            application.profileId
                                                ? ECollections.profProfiles
                                                : ECollections.users
                                        }/${profInfos.documentId}`,
                                    )
                                }
                            >
                                <StarDisplay stars={profInfos.averageRating} />
                                <CText message={`(${profInfos.reviewCount})`} />
                            </TouchableView>
                        ) : (
                            <CText
                                headline
                                message={applicationMessages.tempProfile}
                            />
                        )
                    ) : (
                        <Spinner />
                    )}
                </View>
                <View
                    style={[style.horizontalPadded, { width: localCellWidth }]}
                >
                    <CText message={applicationMessages.rate} />
                    <CText
                        headline
                        message={
                            rate > 0
                                ? convertNumberToCurrencyString(rate, region)
                                : format(applicationMessages.noRate)
                        }
                    />
                </View>
            </View>
            <View style={[style.horizontalSplit, style.horizontalWrap]}>
                {agency ? (
                    <View
                        style={[
                            style.horizontal,
                            style.centeredItems,
                            style.verticalPadded,
                        ]}
                    >
                        <View style={{ paddingHorizontal: 22 }}>
                            <ProfilePicture data={agency} smaller />
                        </View>
                        <CText
                            message={agency.name}
                            secondaryHeadline
                            style={style.horizontalPadded}
                        />
                    </View>
                ) : (
                    <View />
                )}
                <View
                    style={[
                        style.horizontal,
                        { minWidth: 341, justifyContent: 'flex-end' },
                    ]}
                >
                    <CButton onPress={getResume} icon="download" />
                    {application.status === EApplicationStatus.open && (
                        <>
                            <CButton
                                icon={'close'}
                                onPress={async () =>
                                    await handleStatusChange(
                                        application,
                                        EApplicationStatus.rejected,
                                    )
                                }
                                style={{
                                    backgroundColor: theme.errorColor,
                                }}
                                cy={'decline-' + application.applicantUid}
                                disableOnClick={true}
                            />
                            <CButton
                                icon={'check'}
                                onPress={async () => {
                                    await handleStatusChange(
                                        application,
                                        EApplicationStatus.accepted,
                                    );
                                }}
                                title={jobApplicationMessages.accept}
                                style={{
                                    backgroundColor: theme.successColor,
                                }}
                                cy={'accept-' + application.applicantUid}
                                disableOnClick={true}
                            />
                        </>
                    )}
                    {application.status === EApplicationStatus.negotiating && (
                        <>
                            <CButton
                                onPress={cancelContract}
                                icon={'close'}
                                danger
                            />
                            <TouchableView
                                onPress={
                                    application.contractId
                                        ? () =>
                                              navigate(
                                                  '/negotiations/' +
                                                      application.contractId,
                                              )
                                        : undefined
                                }
                            >
                                <CText
                                    message={applicationMessages.negotiating}
                                    style={{
                                        borderColor: theme.accentColor,
                                        borderWidth: 1,
                                        borderRadius: 10,
                                        padding: 10,
                                        margin: 5,
                                        height: 40,
                                        color: theme.accentColor,
                                        fontWeight: 'bold',
                                    }}
                                />
                            </TouchableView>
                        </>
                    )}
                </View>
            </View>
        </CCard>
    );
};
