import { defineMessages } from 'react-intl';

const scope = 'ge.';

export const generalMessages = defineMessages({
    personalDetails: {
        id: scope + 'personalDetails',
        defaultMessage: 'Personal Details',
    },
    overview: {
        id: scope + 'overview',
        defaultMessage: 'Dashboard',
    },
    profile: {
        id: scope + 'profile',
        defaultMessage: 'My Account',
    },
    professionalProfile: {
        id: scope + 'professionalProfile',
        defaultMessage: 'Talentprofile',
    },
    professionalProfiles: {
        id: scope + 'professionalProfiles',
        defaultMessage: 'Talentprofiles',
    },
    calendar: {
        id: scope + 'calendar',
        defaultMessage: 'Calendar',
    },
    referrals: {
        id: scope + 'referrals',
        defaultMessage: 'Referrals',
    },
    employments: {
        id: scope + 'employments',
        defaultMessage: 'Employments',
    },
    jobs: {
        id: scope + 'jobs',
        defaultMessage: 'Jobs',
    },
    applications: {
        id: scope + 'applications',
        defaultMessage: 'Applications',
    },
    workplaces: {
        id: scope + 'workplaces',
        defaultMessage: 'Workplaces',
    },
    agencies: {
        id: scope + 'agencies',
        defaultMessage: 'Agencies',
    },
    notifications: {
        id: scope + 'notifications',
        defaultMessage: 'Notifications',
    },
    helpCenter: {
        id: scope + 'helpCenter',
        defaultMessage: 'Help Center',
    },
    billingCenter: {
        id: scope + 'billingCenter',
        defaultMessage: 'Billing center',
    },
    settings: {
        id: scope + 'settings',
        defaultMessage: 'Settings',
    },
    supportRequests: {
        id: scope + 'supportRequests',
        defaultMessage: 'Support Requests',
    },
    language: {
        id: scope + 'language',
        defaultMessage: 'Language',
    },
    somethingWrong: {
        id: scope + 'somethingWrong',
        defaultMessage:
            'Something is filled in incorrectly. Please check above',
    },
    welcome: {
        id: scope + 'welcome',
        defaultMessage: 'Welcome',
    },
    checkOut: {
        id: scope + 'checkOut',
        defaultMessage: 'Check out the latest updates on your account',
    },
    eMailNotVerified: {
        id: scope + 'eMailNotVerified',
        defaultMessage: 'Your e-mail is not verified',
    },
    eMailNotVerifiedMessage: {
        id: scope + 'eMailNotVerifiedMessage',
        defaultMessage:
            'Please click on the link we have send to {mail}, or re-issue the activation mail. If you are unable to locate the mail we send you please make sure you have checked your spam folder',
    },
    missingBankingInformation: {
        id: scope + 'missingBankingInformation',
        defaultMessage: 'You have not provided banking information',
    },
    missingAddressInformation: {
        id: scope + 'missingAddressInformation',
        defaultMessage: 'You have not provided address information',
    },
    missingDocuments: {
        id: scope + 'missingDocuments',
        defaultMessage: 'You have not provided all recommended documents',
    },
    user: {
        id: scope + 'user',
        defaultMessage: 'User',
    },
    agent: {
        id: scope + 'agent',
        defaultMessage: 'Agent',
    },
    agency: {
        id: scope + 'agency',
        defaultMessage: 'Agency',
    },
    employer: {
        id: scope + 'employer',
        defaultMessage: 'Employer',
    },
    doctor: {
        id: scope + 'doctor',
        defaultMessage: 'Doctor',
    },
    nurse: {
        id: scope + 'nurse',
        defaultMessage: 'Nurse',
    },
    teacher: {
        id: scope + 'teacher',
        defaultMessage: 'Teacher',
    },
    socialWorker: {
        id: scope + 'socialWorker',
        defaultMessage: 'Social worker',
    },
    student: {
        id: scope + 'student',
        defaultMessage: 'Student',
    },
    unsavedChanges: {
        id: scope + 'unsavedChanges',
        defaultMessage: 'You have unsaved changes',
    },
    ifContinueTheyDiscard: {
        id: scope + 'ifContinueTheyDiscard',
        defaultMessage: 'If you continue this action they get discarded.',
    },
    clickHereToResolve: {
        id: scope + 'clickHereToResolve',
        defaultMessage: 'Click here to resolve',
    },
    other: {
        id: scope + 'other',
        defaultMessage: 'Other (please specify)',
    },
    otherPlaceholder: {
        id: scope + 'otherPlaceholder',
        defaultMessage: 'Please specify',
    },
    missingPersonalInformations: {
        id: scope + 'missingPersonalInformations',
        defaultMessage:
            'You have not provided informations regarding your personal qualifications',
    },
    eMailVerified: {
        id: scope + 'eMailVerified',
        defaultMessage: 'E-Mail verified!',
    },
    completedAddressInformation: {
        id: scope + 'completedAddressInformation',
        defaultMessage: 'Address informations are complete!',
    },
    completedBankingInformation: {
        id: scope + 'completedBankingInformation',
        defaultMessage: 'Banking informations are complete!',
    },
    addMFAText: {
        id: scope + 'addMFAText',
        defaultMessage:
            'Please add multi factor authorization to your account!',
    },
    addMFA: {
        id: scope + 'addMFA',
        defaultMessage: 'Your account is not multi factor authorized!',
    },
    addedMFA: {
        id: scope + 'addedMFA',
        defaultMessage: 'Your login is multi factor authorized!',
    },
    addIdDocText: {
        id: scope + 'addIdDocText',
        defaultMessage:
            'Adding a valid id document will enable you to sign document digitally!',
    },
    addIdDoc: {
        id: scope + 'addIdDoc',
        defaultMessage: 'Please add a id document!',
    },
    addedIdDoc: {
        id: scope + 'addedIdDoc',
        defaultMessage: 'You have added a valid id document!',
    },
    completedPersonalInformations: {
        id: scope + 'completedPersonalInformations',
        defaultMessage:
            'Informations about personal qualifications are complete!',
    },
    light: {
        id: scope + 'light',
        defaultMessage: 'Light',
    },
    dark: {
        id: scope + 'dark',
        defaultMessage: 'Dark',
    },
    selectToday: {
        id: scope + 'selectToday',
        defaultMessage: 'Select Today',
    },
    admin: {
        id: scope + 'admin',
        defaultMessage: 'Admin',
    },
    disabled: {
        id: scope + 'disabled',
        defaultMessage: 'Disabled',
    },
    workplace: {
        id: scope + 'workplace',
        defaultMessage: 'Workplace',
    },
    moderator: {
        id: scope + 'moderator',
        defaultMessage: 'Moderator',
    },
    supermoderator: {
        id: scope + 'supermoderator',
        defaultMessage: 'Super moderator',
    },
    default: {
        id: scope + 'default',
        defaultMessage: 'Default',
    },
    basic: {
        id: scope + 'basic',
        defaultMessage: 'Basic',
    },
    talent: {
        id: scope + 'talent',
        defaultMessage: 'Talent',
    },
    users: {
        id: scope + 'users',
        defaultMessage: 'Users',
    },
    professionalUsers: {
        id: scope + 'professionalUsers',
        defaultMessage: 'Profiles',
    },
    sendVerification: {
        id: scope + 'sendVerification',
        defaultMessage: 'Click here to resend verification mail',
    },
    userVerified: {
        id: scope + 'userVerified',
        defaultMessage: 'You are verified by {platform}',
    },
    userNotVerified: {
        id: scope + 'userNotVerified',
        defaultMessage: 'Your are not verified',
    },
    requestVerificationFromAgent: {
        id: scope + 'requestVerificationFromAgent',
        defaultMessage:
            'Book a onboarding to get verified by one of our agents.',
    },
    theme: {
        id: scope + 'theme',
        defaultMessage: 'Color theme',
    },
    recentNotifications: {
        id: scope + 'recentNotifications',
        defaultMessage: 'Recent notifications',
    },
    upcomingJobs: {
        id: scope + 'upcomingJobs',
        defaultMessage: 'Upcoming jobs',
    },
    matchingJobs: {
        id: scope + 'matchingJobs',
        defaultMessage: 'Matching jobs',
    },
    jobApplications: {
        id: scope + 'jobApplications',
        defaultMessage: 'Jobs & Applicants',
    },
    showMore: {
        id: scope + 'showMore',
        defaultMessage: 'Show more',
    },
    recent: {
        id: scope + 'recent',
        defaultMessage: 'Recent',
    },
    archived: {
        id: scope + 'archived',
        defaultMessage: 'Archived',
    },
    pinned: {
        id: scope + 'pinned',
        defaultMessage: 'Pinned',
    },
    public: {
        id: scope + 'public',
        defaultMessage: 'Public',
    },
    nearby: {
        id: scope + 'nearby',
        defaultMessage: 'Nearby',
    },
    more: {
        id: scope + 'more',
        defaultMessage: 'More',
    },
    forMe: {
        id: scope + 'forMe',
        defaultMessage: 'For me',
    },
    all: {
        id: scope + 'all',
        defaultMessage: 'All',
    },
    true: {
        id: scope + 'true',
        defaultMessage: 'Yes',
    },
    false: {
        id: scope + 'false',
        defaultMessage: 'No',
    },
    chat: {
        id: scope + 'chat',
        defaultMessage: 'Chat',
    },
    contracts: {
        id: scope + 'contracts',
        defaultMessage: 'Contracts',
    },
    contractHistory: {
        id: scope + 'contractHistory',
        defaultMessage: 'Contract History',
    },
    negotiations: {
        id: scope + 'negotiations',
        defaultMessage: 'Negotiations',
    },
    day: {
        id: scope + 'day',
        defaultMessage: 'Day',
    },
    days: {
        id: scope + 'days',
        defaultMessage: 'Days',
    },
    year: {
        id: scope + 'year',
        defaultMessage: 'Year',
    },
    years: {
        id: scope + 'years',
        defaultMessage: 'Years',
    },
    hour: {
        id: scope + 'hour',
        defaultMessage: 'Hour',
    },
    hours: {
        id: scope + 'hours',
        defaultMessage: 'Hours',
    },
    minute: {
        id: scope + 'minute',
        defaultMessage: 'Minute',
    },
    minutes: {
        id: scope + 'minutes',
        defaultMessage: 'Minutes',
    },
    accountIsDisabled: {
        id: scope + 'accountIsDisabled',
        defaultMessage: 'Your account is disabled',
    },
    accountIsDisableText: {
        id: scope + 'accountIsDisableText',
        defaultMessage:
            'Your account has been disabled. Contact us at support@{domain} to regain access.',
    },
    newChatMessage: {
        id: scope + 'newChatMessage',
        defaultMessage: 'New chat message',
    },
    newNotification: {
        id: scope + 'newNotification',
        defaultMessage: 'New notification',
    },
    newSupportRequest: {
        id: scope + 'newSupportRequest',
        defaultMessage: 'New support request',
    },
    for: {
        id: scope + 'for',
        defaultMessage: 'For',
    },
    loadingResume: {
        id: scope + 'loadingResume',
        defaultMessage: 'Loading resume...',
    },
    pleaseSelect: {
        id: scope + 'pleaseSelect',
        defaultMessage: 'Please select an option',
    },
    resume: {
        id: scope + 'resume',
        defaultMessage: 'Resume',
    },
    options: {
        id: scope + 'options',
        defaultMessage: 'Options',
    },
    noResults: {
        id: scope + 'noResults',
        defaultMessage: 'No results found. Try different search parameters.',
    },
    unknown: {
        id: scope + 'unknown',
        defaultMessage: 'Unknown',
    },
    undefined: {
        id: scope + 'undefined',
        defaultMessage: 'Undefined',
    },
    completeYourOnboarding: {
        id: scope + 'completeYourOnboarding',
        defaultMessage: 'Welcome back!',
    },
    completeYourOnboardingMessage: {
        id: scope + 'completeYourOnboardingMessage',
        defaultMessage:
            'You have left us during your onboarding. Please complete it to access the App. We tried our best to restore previously entered values.',
    },
    alreadyMFA: {
        id: scope + 'alreadyMFA',
        defaultMessage: 'Your account already has multi factor authentication',
    },
    licensedAgency: {
        id: scope + 'licensedAgency',
        defaultMessage: 'Your agency has a valid license.',
    },
    unlicensedAgency: {
        id: scope + 'unlicensedAgency',
        defaultMessage:
            'The agency you belong to has not yet uploaded a valid license or the license is still in review.',
    },
    licenseInReview: {
        id: scope + 'licenseInReview',
        defaultMessage:
            'Your agency has a license, but it still needs verification by one of our agents. You get notified once verified.',
    },
    errorOccured: {
        id: scope + 'errorOccured',
        defaultMessage: 'Something went wrong.',
    },
    openUrl: {
        id: scope + 'openUrl',
        defaultMessage: 'You are about to leave {platform}',
    },
    openUrlText: {
        id: scope + 'openUrlText',
        defaultMessage:
            'You are about to open a external site. We can not guarantee that it follows our guidelines. Please be careful and do not enter any personal information on this site.',
    },
    myAgencies: {
        id: scope + 'myAgencies',
        defaultMessage: 'My agencies',
    },
    addressAgency: {
        id: scope + 'addressAgency',
        defaultMessage: 'Your agency has a address.',
    },
    noAddressAgency: {
        id: scope + 'noAddressAgency',
        defaultMessage: 'Your agency has no address',
    },
    pictureAgency: {
        id: scope + 'pictureAgency',
        defaultMessage: 'Your agency has a picture',
    },
    noPictureAgency: {
        id: scope + 'noPictureAgency',
        defaultMessage: 'Your agency has no picture',
    },
    wrongRegionLogin: {
        id: scope + 'wrongRegionLogin',
        defaultMessage:
            'You tried to login to another region than your account is registered to.',
    },
    wrongRegionLoginText: {
        id: scope + 'wrongRegionLoginText',
        defaultMessage:
            'Your account is registered to region: {yourRegion}. Please visit {correctUrl} and login from there.',
    },
    wrongPlatformLogin: {
        id: scope + 'wrongPlatformLogin',
        defaultMessage:
            'You tried to login to {platformA} with a account for {platformB}.',
    },
    wrongPlatformLoginText: {
        id: scope + 'wrongPlatformLoginText',
        defaultMessage:
            'Your account is registered to platform: {platformB}. Please visit {correctUrl} and login from there.',
    },
    documentReview: {
        id: scope + 'documentReview',
        defaultMessage: 'ID Review',
    },
    adminSaveConfirm: {
        id: scope + 'adminSaveConfirm',
        defaultMessage: 'You are about to change user data.',
    },
    adminSaveConfirmText: {
        id: scope + 'adminSaveConfirmText',
        defaultMessage: 'Confirm that you intended to do so.',
    },
    envelope: {
        id: scope + 'envelope',
        defaultMessage: 'Envelope',
    },
    validUntil: {
        id: scope + 'validUntil',
        defaultMessage: 'Valid until',
    },
    availableUsers: {
        id: scope + 'availableUsers',
        defaultMessage: 'Available Talents',
    },
    requests: {
        id: scope + 'requests',
        defaultMessage: 'Requests',
    },
    talentRequests: {
        id: scope + 'talentRequests',
        defaultMessage: 'Talent requests',
    },
    shiftplan: {
        id: scope + 'shiftplan',
        defaultMessage: 'Shift planner',
    },
    characters: {
        id: scope + 'characters',
        defaultMessage: 'Characters',
    },
    mail: {
        id: scope + 'mail',
        defaultMessage: 'Mail',
    },
    orderBy: {
        id: scope + 'orderBy',
        defaultMessage: 'Order by',
    },
    createdOn: {
        id: scope + 'createdOn',
        defaultMessage: 'Created on',
    },
    createdBy: {
        id: scope + 'createdBy',
        defaultMessage: 'Created by',
    },
    contactUs: {
        id: scope + 'contactUs',
        defaultMessage: 'contact us',
    },
    checkSettings: {
        id: scope + 'checkSettings',
        defaultMessage: 'Check settings',
    },
    jobNotIncluded: {
        id: scope + 'jobNotIncluded',
        defaultMessage: 'You can not find your desired job specification?',
    },
    jobNotIncludedText: {
        id: scope + 'jobNotIncludedText',
        defaultMessage:
            'Check if you have the related field enabled in your settings or send us a request so we can improve our list of fields professions educations and specializations.',
    },
    month: { id: scope + 'month', defaultMessage: 'Month' },
    week: { id: scope + 'week', defaultMessage: 'Week' },
    confirmRowRemoval: {
        id: scope + 'confirmRowRemoval',
        defaultMessage: 'Do you want to remove this row?',
    },
    confirmRowRemovalText: {
        id: scope + 'confirmRowRemovalText',
        defaultMessage: 'Please confirm that you want to remove this row.',
    },
});
