import React, { FC, useContext, useEffect, useMemo } from 'react';
import { useFormat } from '../../../utilities/intl';
import { CTextInput } from '../../../components';
import { onboardingMessages } from '../Onboarding.messages';
import { ProfileContext } from '../../Profile/Profile.context';

export const AgencyInformation: FC<{
    agency: string;
    setAgency: (a: string) => void;
}> = ({ agency, setAgency }) => {
    const format = useFormat();
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    /**
     * validity function for workplace
     */
    const agencyEmpty = useMemo(() => {
        if (!agency) {
            return format(onboardingMessages.noAgencyName);
        }
    }, [agency]);
    /**
     * validity function for workplace
     */
    const taxNumberEmpty = useMemo(() => {
        if (!curData.taxNumber) {
            return format(onboardingMessages.noTaxNumber);
        }
    }, [curData]);
    /**
     * validity function for workplace
     */
    const registerNumberEmpty = useMemo(() => {
        if (!curData.registerNumber) {
            return format(onboardingMessages.noRegisterNumber);
        }
    }, [curData]);
    /**
     * set validity
     */
    useEffect(() => {
        const agencyError =
            agencyEmpty || taxNumberEmpty || registerNumberEmpty;
        onValidityChange(agencyError || '');
    }, [agencyEmpty, taxNumberEmpty, registerNumberEmpty]);

    return (
        <>
            <CTextInput
                cy={'agency-name'}
                value={agency}
                onChangeText={setAgency}
                // placeholder={format(profileMessages.workplacePlaceholder)}
                label={format(onboardingMessages.agencyName)}
                autoExtend
                customCheckInvalidity={() => agencyEmpty}
            />
            <CTextInput
                cy={'tax-number'}
                value={curData.taxNumber}
                onChangeText={(taxNumber) => onChange({ taxNumber })}
                // placeholder={format(profileMessages.workplacePlaceholder)}
                label={format(onboardingMessages.taxNumber)}
                autoExtend
                customCheckInvalidity={() => taxNumberEmpty}
            />
            <CTextInput
                cy={'register-number'}
                value={curData.registerNumber}
                onChangeText={(registerNumber) => onChange({ registerNumber })}
                // placeholder={format(profileMessages.workplacePlaceholder)}
                label={format(onboardingMessages.registerNumber)}
                autoExtend
                customCheckInvalidity={() => registerNumberEmpty}
            />
        </>
    );
};
