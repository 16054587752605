import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { AddressInput } from '../../../components/AddressInput';
import { CCheckBox, CText, CTextInput, Radio } from '../../../components';
import { MUserData } from '../../../models';
import { useFormat } from '../../../utilities/intl';
import { useStyle } from '../../../utilities/styles';
import { profileMessages } from '../Profile.messages';
import { EUserType } from '../../../enums';
import { onboardingMessages } from '../../Onboarding/Onboarding.messages';
import { ProfileContext } from '../Profile.context';

export const ProfileSectionAddress: FC = () => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const {
        curData,
        onChange: propOnChange,
        onValidityChange,
    } = useContext(ProfileContext);
    /**
     * onChange variation to be able to make use of abe
     */
    const onChange = useCallback(
        (next: Partial<MUserData>) => {
            if (next.address && curData.billingAddressDiffers) {
                next.billingAddress = next.address;
            }
            propOnChange(new MUserData({ ...curData, ...next }));
        },
        [curData],
    );
    /**
     * memoized travel level
     */
    const w = useMemo(() => {
        if (curData.willingToTravel && !curData.notWillingToTravel) {
            return 3;
        } else if (!curData.willingToTravel && !curData.notWillingToTravel) {
            return 2;
        } else if (!curData.willingToTravel && curData.notWillingToTravel) {
            return 1;
        }
    }, [curData]);
    /**
     * effect to clear validity
     */
    useEffect(() => {
        const cadr = curData.address;
        onValidityChange(
            !cadr.lng || !cadr.lat
                ? format(onboardingMessages.provideAddress)
                : '',
        );
    }, [curData]);
    /**
     * render
     */
    return (
        <>
            {curData.type === EUserType.user && (
                <CText style={style.verticalPadded}>
                    {format(profileMessages.addressExplanationUser)}
                </CText>
            )}
            <AddressInput
                cy={'user-address'}
                title={format(profileMessages.address)}
                address={curData.address}
                setAddress={(address) => onChange({ address })}
            />
            {(curData.type === EUserType.employer ||
                curData.type === EUserType.agency) && (
                <View style={style.verticalPadded}>
                    <CCheckBox
                        checked={curData.billingAddressDiffers}
                        onCheckedChanged={(c) =>
                            onChange({ billingAddressDiffers: c })
                        }
                        title={format(profileMessages.billingAddressDiffers)}
                    />
                </View>
            )}
            {curData.billingAddressDiffers && (
                <AddressInput
                    title={format(profileMessages.billingAddress)}
                    address={curData.billingAddress}
                    setAddress={(billingAddress) =>
                        onChange({ billingAddress })
                    }
                />
            )}
            {curData.type === EUserType.user && (
                <>
                    <Radio
                        cy={'travel-radio'}
                        values={[
                            {
                                label: format(profileMessages.willingToTravel),
                                value: 3,
                            },
                            {
                                label: format(profileMessages.neutralToTravel),
                                value: 2,
                            },
                            // {
                            //     label: format(
                            //         profileMessages.notWillingToTravel,
                            //     ),
                            //     value: 1,
                            // },
                        ]}
                        value={w}
                        onChange={(next) => {
                            if (next === 3) {
                                onChange({
                                    willingToTravel: true,
                                    notWillingToTravel: false,
                                });
                            } else if (next === 2) {
                                onChange({
                                    willingToTravel: false,
                                    notWillingToTravel: false,
                                });
                            } else if (next) {
                                onChange({
                                    willingToTravel: false,
                                    notWillingToTravel: true,
                                });
                            }
                        }}
                    />
                    {!curData.willingToTravel && !curData.notWillingToTravel && (
                        <CTextInput
                            value={`${curData.maxTravelDistance}`}
                            onChangeText={(t) => {
                                if (!isNaN(+t)) {
                                    onChange({ maxTravelDistance: +t });
                                }
                            }}
                            unit={'km'}
                            label={format(profileMessages.maxTravelDistance)}
                            placeholder={format(
                                profileMessages.maxTravelDistancePlaceholder,
                            )}
                        />
                    )}
                </>
            )}
        </>
    );
};
