import { EApplicationStatus, EField, EGender, EProfession } from '../enums';
import { EApplicationType } from '../enums/EApplicationType';
import { MJob } from './MJob';
import { MRates } from './MRates';
/**
 * class for a application from a user / agency for a job
 */
export class MApplication {
    public documentId = '';

    public applicantUid = '';
    public agencyId?: string;
    public profileId?: string;
    public customDocumentPath?: string;

    public editedOn = Date.now();
    public createdOn = Date.now();

    public jobId = '';
    public jobAuthor = '';
    public jobWorkplace = '';
    public job = new MJob();

    public from = 0;
    public to = 0;
    public fromNow = false;

    public status: EApplicationStatus = EApplicationStatus.open;
    public type: EApplicationType = EApplicationType.user;
    public contractId?: string;

    public field: EField = EField.medic;
    public profession: EProfession = EProfession.doctor;
    public educations: string[] = [];
    public specializations: string[] = [];
    public yearsOfExperience = 0;
    public position = '';
    public noCrossGenderWork = false;
    public gender = EGender.diverse;
    public languages: { lang: string; level: string }[] = [];

    public wage: MRates = new MRates();

    constructor(params: Partial<MApplication> = {}) {
        Object.assign(this, params);

        if (params.job) {
            this.job = new MJob(params.job);
        }

        if ((params as any).education && !params.educations?.length) {
            this.educations = [(params as any).education];
        }

        if (params.wage) {
            /**
             * * legacy wage fix
             */
            if (typeof params.wage === 'number') {
                const uPs = params as any;

                this.wage = new MRates({
                    wage: uPs.wage,
                    regularWage: uPs.regularWage,
                    standbyWage: uPs.standbyWage,
                    onCallActiveWage: uPs.onCallActiveWage,
                    onCallPassiveWage: uPs.onCallPassiveWage,
                });
            } else {
                /**
                 * wage assign
                 */
                this.wage = new MRates(params.wage);
            }
        }
    }
}
