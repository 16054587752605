import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { CButton, CTextInput, CIcon, InfoBox } from '../../../components';
import { useStyle, useTheme } from '../../../utilities/styles';
import { View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import { profileMessages } from '../Profile.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { ProfileContext } from '../Profile.context';
import { useFireBase } from '../../../utilities/firebase';
import { useSecureNavigate } from '../../../utilities/routing';
import { documentScanMessages } from '../../DocumentScan/documentScan.messages';
import { MUserIdentDoc } from '../../../models';
import { ECollections } from '../../../enums';
/**
 * profile section for basic user informations.
 * all human usertypes have access
 * @param param0 props
 * @returns component
 */
export const ProfileSectionIdentity: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { user, mfaInfo, getDataIndex } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    const { theme } = useTheme();
    // parent state
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    const [curDocs, setCurDocs] = useState<MUserIdentDoc[]>([]);
    const expiredDocs = useMemo(
        () => curDocs.filter((d) => d.expiresOn <= Date.now()),
        [curDocs],
    );
    const reviewedAndActiveDocs = useMemo(
        () => curDocs.filter((d) => d.reviewed && d.expiresOn > Date.now()),
        [curDocs],
    );
    /**
     * memoize if this is in profile or user edit
     */
    const isSelfEdit = useMemo(
        () => user?.uid === curData.documentId,
        [user, curData],
    );
    /**
     * callback for phone validity
     */
    const phoneValidity = useCallback((phone: string) => {
        if (!phone) {
            return format(profileMessages.phoneMissingError);
        }
        // } else if (
        //     !phone.match(
        //         /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        //     )
        // ) {
        //     return format(profileMessages.phoneMalformatedError);
        // }
    }, []);
    /**
     * effect to determine validity of inputs
     */
    useEffect(() => {
        onValidityChange(
            phoneValidity(curData.phone)
                ? format(generalMessages.somethingWrong)
                : '',
        );
    }, [curData]);
    /**
     * effect to load id docs
     */
    useEffect(() => {
        if (!curData.documentId) {
            return;
        }
        getDataIndex(
            `${ECollections.users}/${curData.documentId}/${ECollections.identDocuments}`,
        ).then((res) => {
            setCurDocs((res as any[]).map((r) => new MUserIdentDoc(r)));
        });
    }, [curData]);
    /**
     * render
     */
    return (
        <>
            <View style={[style.horizontal, { alignItems: 'flex-end' }]}>
                <View style={style.flex1}>
                    <CTextInput
                        cy={'phone'}
                        value={curData.phone}
                        onChangeText={(phone) => onChange({ phone })}
                        placeholder={format(profileMessages.phonePlaceholder)}
                        label={format(profileMessages.phone)}
                        autoExtend
                        customCheckInvalidity={phoneValidity}
                        disabled
                    />
                </View>
                {isSelfEdit &&
                    (!mfaInfo ? (
                        <View style={style.verticalPadded}>
                            <CButton
                                title={profileMessages.setup2FA}
                                onPress={() => {
                                    secureNavigate('/multifactorsetup');
                                }}
                            />
                        </View>
                    ) : (
                        <View style={{ marginBottom: 25 }}>
                            <CIcon icon={'check'} tint={theme.successColor} />
                        </View>
                    ))}
            </View>
            <View>
                {!!reviewedAndActiveDocs.length && (
                    <InfoBox
                        type="success"
                        message={profileMessages.youHaveAvalidAndActiveIdDoc}
                    />
                )}
                {!mfaInfo && (
                    <View style={style.verticalPadded}>
                        <InfoBox
                            type="warning"
                            message={generalMessages.addMFAText}
                        />
                    </View>
                )}
                <CButton
                    disabled={!mfaInfo}
                    title={documentScanMessages.uploadANewIdentityDocument}
                    onPress={() => {
                        secureNavigate('/documentScan');
                    }}
                />
            </View>
        </>
    );
};
