import React, { FC, useState } from 'react';
import { Linking, View } from 'react-native';
import { MFile } from '../../models';
import { useFireBase } from '../../utilities/firebase';
import { useFormat } from '../../utilities/intl';
import { filenameMessages, generalMessages } from '../../utilities/messages';
import { useStyle, useTheme } from '../../utilities/styles';
import { DocumentPreview } from '../DocumentPreview';
import { CText, CButton, CDatePicker, CIcon } from '../elements';
import { getContractFileColor } from '../../utilities/constants';
import { TouchableView } from '../TouchableView';

export const DocumentPreviewRow: FC<{
    file: MFile;
    onDelete?: () => void;
    onChangeValidUntil?: (next: number) => void;
}> = ({ file, onDelete, onChangeValidUntil }) => {
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const { getFileDownloadUrl } = useFireBase();
    const [open, setOpen] = useState(false);

    return (
        <View
            style={[
                { borderRadius: 5 },
                Date.now() > file.validUntil && style.errorBorder,
            ]}
        >
            <View style={style.horizontalSplit}>
                <TouchableView
                    style={[
                        style.horizontal,
                        style.centeredItems,
                        { flexGrow: 1 },
                    ]}
                    onPress={() => setOpen(!open)}
                >
                    <CIcon icon={open ? 'chevronDown' : 'chevronRight'} />
                    <CText
                        message={filenameMessages[file.type]}
                        style={[
                            {
                                color: getContractFileColor(file.type),
                                borderColor: getContractFileColor(file.type),
                                borderWidth: 1,
                                borderRadius: 3,
                                padding: 2,
                                fontSize: 12,
                            },
                            style.horizontalPadded,
                        ]}
                    />
                    <CText numberOfLines={1} bold>
                        {file.name}
                    </CText>
                </TouchableView>
                <View style={[style.horizontal, style.centeredItems]}>
                    <CDatePicker
                        value={new Date(file.validUntil)}
                        onChange={(v) => {
                            if (onChangeValidUntil) {
                                onChangeValidUntil(v.getTime());
                            }
                        }}
                        title={generalMessages.validUntil}
                        horizontal
                    />
                    <CButton
                        icon="download"
                        onPress={() => {
                            getFileDownloadUrl(file.path).then((u) => {
                                Linking.openURL(u);
                            });
                        }}
                        minor
                    />
                    {onDelete && (
                        <CButton
                            icon={'close'}
                            iconColor={theme.errorColor}
                            onPress={onDelete}
                            minor
                        />
                    )}
                </View>
            </View>
            {open && <DocumentPreview filename={file.path} forceOpen />}
        </View>
    );
};
