import {
    EEmploymentType,
    EField,
    EJobStatus,
    EProfession,
    ERegion,
    EWageLevel,
} from '../enums';
import { MAddress } from './MAddress';
import { IDayAvailability } from './MAvailability';
import { MRates } from './MRates';

export class MJob {
    public documentId = '';

    public author = '';

    public editedOn = Date.now();
    public createdOn = Date.now();

    public id = 0;

    public title = '';
    public fromNowUntillUndefined = false;
    public from = Date.now();
    public to?: number;
    public activeDates: string[] = [];
    public activeMonths: number[] = [];
    public activeMonthsObject: any = {};

    public status: EJobStatus = EJobStatus.public;

    public type: EEmploymentType = EEmploymentType.personnelLeasing;
    public location = '';
    public fullAddress?: MAddress;
    public useFullAddress = false;
    public workingTimes = '';
    public liability = '';
    public accommodation = '';
    public otherSelfAccommodation = '';
    public travelPay = false;

    public freeText = '';

    public workPlaceId = '';
    public workplaceId = '';
    public workplaceUsers: string[] = [];
    public mail = '';

    public employeeField: EField = EField.undefined;
    public employeeProfession: EProfession = EProfession.undefined;
    public employeePosition = '';
    public employeeYoe = 0;
    public employeeEducations: string[] = [];
    public employeeSpecializations: string[] = [];
    public employeeAcademicTitle = false;

    public region = ERegion.de;

    public agencies: string[] = [];

    public wageLevel: EWageLevel = EWageLevel.above_average;
    public wage = new MRates();

    public days: IDayAvailability[] = [];

    constructor(params: Partial<MJob> = {}) {
        Object.assign(this, params);

        MJob.fixConstructor(this, params);
    }
    /**
     * static method to fix shorcommings of object assign and patch compatibilies with incomplete paramsets
     * @param that object that just got object assigned
     * @param params params to apply fixes with
     */
    static fixConstructor = (that: MJob, params: Partial<MJob>) => {
        if (params.type) {
            const type = params.type as string;

            that.type =
                type === 'shortTerm'
                    ? EEmploymentType.personnelLeasing
                    : type === 'permanent'
                    ? EEmploymentType.fullTime
                    : (type as EEmploymentType);
        }
        if (params.fullAddress) {
            that.fullAddress = new MAddress(params.fullAddress);
        }
        /**
         * unsafe params for compatibility with old data
         */
        const unsafeParams = params as any;

        if (unsafeParams.hospital) {
            that.workPlaceId = unsafeParams.hospital;
        }

        if (unsafeParams.employeeType) {
            that.employeeProfession = unsafeParams.employeeType;
        }

        if (typeof unsafeParams.wage === 'string') {
            that.wage = new MRates();
            that.wageLevel = unsafeParams.wage;
        } else {
            that.wage = new MRates(params.wage);
        }

        if (
            (!params.employeeField ||
                params.employeeField === EField.undefined) &&
            params.employeeProfession
        ) {
            if (
                [EProfession.doctor, EProfession.nurse].includes(
                    params.employeeProfession,
                )
            ) {
                that.employeeField = EField.medic;
            }
        }

        if (params.from && params.to) {
            const nextActiveDates: string[] = [];
            const nextActiveMonths: number[] = [];
            let pivot = params.from;
            const toDate = new Date(params.to);
            toDate.setDate(toDate.getDate() + 1);
            const end = toDate.getTime();
            while (pivot < end) {
                const curDate = new Date(pivot);
                const standardWeekDay = curDate.getDay();
                const curWeekDay = !standardWeekDay ? 7 : standardWeekDay;
                const dayTime = that.days.find((d) => d.day === curWeekDay);
                if (!that.days.length || dayTime) {
                    nextActiveDates.push(curDate.toLocaleDateString('de'));
                    const curMonth = curDate.getMonth();
                    if (!nextActiveMonths.includes(curMonth)) {
                        nextActiveMonths.push(curMonth);
                    }
                }
                curDate.setDate(curDate.getDate() + 1);
                pivot = curDate.getTime();
            }

            that.activeDates = nextActiveDates;
            that.activeMonths = nextActiveMonths;
            that.activeMonthsObject = nextActiveMonths.reduce((acc, m) => {
                const next = { ...acc };
                next[`m${m}`] = true;
                return next;
            }, {} as any);
        }

        if (params.workPlaceId && !params.workplaceId) {
            that.workplaceId = params.workPlaceId;
        }
    };
}
