import React, { FC, useCallback, useContext } from 'react';
import { Linking, View } from 'react-native';
import { useFireBase } from '../../../utilities/firebase';
import { MApplication } from '../../../models/MApplication';
import { CText, CButton } from '../../../components';
import { ApplicationPicture } from '../../../components/ProfilePicture/ApplicationPicture';
import { LanguageContext, useFormat } from '../../../utilities/intl';
import {
    educationMessages,
    specializationMessages,
    positionMessages,
    generalMessages,
} from '../../../utilities/messages';
import { useStyle } from '../../../utilities/styles';
import { jobMessages } from '../../Job/job.messages';
import { profileMessages } from '../../Profile/Profile.messages';
import { EEnvironment } from '../../../enums';
import { useEnvironment } from '../../../utilities/contexts';
import { useLock } from '../../../utilities/hooks';
import { MProfessionalInfo } from '../../../models';
import { applicationMessages } from '../../Application/application.messages';
/**
 * overview of applicant connected to contract
 * @param param0
 * @returns
 */
export const ApplicantOverviewBar: FC<{
    application: MApplication;
    applicant?: MProfessionalInfo;
}> = ({ application, applicant }) => {
    // global state
    const { getFile, callFunction } = useFireBase();
    const format = useFormat();
    const style = useStyle();
    const { lock } = useLock();
    const { environment } = useEnvironment();
    const { language } = useContext(LanguageContext);
    /**
     * callback to get resume
     */
    const getResume = useCallback(async () => {
        const unlock = lock();
        try {
            const filename = await callFunction('getResume', {
                applicationId: application.documentId,
                environment,
                lang: language,
            });
            const res = await getFile(filename);
            const blob = new Blob(
                // @ts-ignore
                [res],
                {
                    type: 'application/pdf',
                },
            );

            Linking.openURL(URL.createObjectURL(blob));
        } finally {
            unlock();
        }
    }, [application, language, environment]);
    /**
     * render
     */
    return (
        <View style={[style.card, style.horizontalWrap, style.horizontalSplit]}>
            <View
                style={[
                    style.horizontalPadded,
                    style.horizontal,
                    { flexGrow: 1 },
                ]}
            >
                <ApplicationPicture data={application} />
                {!!applicant && (
                    <View style={[style.horizontalPadded]}>
                        <CText
                            message={
                                applicant?.firstName +
                                (applicant.firstName ? ' ' : '') +
                                applicant?.lastName
                            }
                        />
                        <CText
                            headline
                            message={application.educations
                                .map((e) =>
                                    format(
                                        educationMessages[
                                            e as keyof typeof educationMessages
                                        ],
                                    ),
                                )
                                .join(',\n')}
                        />
                    </View>
                )}
            </View>
            {!!application.specializations.length && (
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={jobMessages.specializations} />
                    <CText
                        headline
                        message={application.specializations
                            .map((spec) =>
                                format(
                                    specializationMessages[
                                        spec as keyof typeof specializationMessages
                                    ],
                                ),
                            )
                            .join(',\n')}
                    />
                </View>
            )}
            {!!application.position && (
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={jobMessages.employeePosition} />
                    <CText
                        headline
                        message={
                            positionMessages[
                                application.position as keyof typeof positionMessages
                            ]
                        }
                    />
                </View>
            )}
            {!!application.yearsOfExperience && (
                <View style={[style.horizontalPadded, { flexGrow: 1 }]}>
                    <CText message={profileMessages.yoe} />
                    <CText
                        headline
                        message={`${application.yearsOfExperience} ${format(
                            application.yearsOfExperience !== 1
                                ? generalMessages.years
                                : generalMessages.year,
                        )}`}
                    />
                </View>
            )}
            <View style={[style.horizontalPadded, style.centeredItems]}>
                <CText message={jobMessages.resume} />
                <CButton onPress={getResume} icon="download" />
            </View>
        </View>
    );
};
