import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    CButton,
    CText,
    CPicker,
    CTextInput,
    CIcon,
    Radio,
    CCheckBox,
} from '../../../components';
import { useStyle, useTheme } from '../../../utilities/styles';
import { View } from 'react-native';
import { CDatePicker } from '../../../components';
import { year } from '../../../utilities/functions';
import { useFormat } from '../../../utilities/intl';
import {
    actionMessages,
    doctorTitleMessages,
    fieldMessages,
} from '../../../utilities/messages';
import { profileMessages } from '../Profile.messages';
import { generalMessages } from '../../../utilities/messages/general.messages';
import { countryMessages } from '../../../utilities/messages/country.messages';
import {
    EField,
    EGender,
    EProfession,
    ERegion,
    EUserType,
} from '../../../enums';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { ProfileContext } from '../Profile.context';
import { useEnvironment } from '../../../utilities/contexts';
import { useFireBase } from '../../../utilities/firebase';
import { useSecureNavigate } from '../../../utilities/routing';
/**
 * profile section for basic user informations.
 * all human usertypes have access
 * @param param0 props
 * @returns component
 */
export const ProfileSectionBasic: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { region } = useEnvironment();
    const { user, mfaInfo } = useFireBase();
    const { secureNavigate } = useSecureNavigate();
    const { theme } = useTheme();
    // parent state
    const { curData, onChange, onValidityChange, onboarding } =
        useContext(ProfileContext);
    /**
     * memoize if this is in profile or user edit
     */
    const isSelfEdit = useMemo(
        () => user?.uid === curData.documentId,
        [user, curData],
    );
    /**
     * memoized to hide overly detailed fields in onboarding for non users
     */
    const hideDetailedFields = useMemo(() => {
        return onboarding && curData.type !== EUserType.user;
    }, [onboarding, curData]);
    /**
     * common country keys sorted as displayed
     */
    const priorisedCountryKeys = useMemo(() => {
        switch (region) {
            case ERegion.de:
                return ['germany'];
            case ERegion.za:
                return ['southAfrica'];
        }
    }, [region]);
    /**
     * all remaining country keys sorted alphabetically
     */
    const otherCountryKeys = useMemo(
        () => [
            ...Object.keys(countryMessages)
                .filter((key) => !priorisedCountryKeys.includes(key))
                .sort(),
        ],
        [priorisedCountryKeys],
    );
    /**
     * validity functions
     */
    const fnValidity = useCallback((fn: string) => {
        if (!fn) {
            return format(profileMessages.firstNameMissingError);
        }
    }, []);
    const lnValidity = useCallback((ln: string) => {
        if (!ln) {
            return format(profileMessages.lastNameMissingError);
        }
    }, []);
    const genderValidity = useCallback((gender?: EGender) => {
        if (!gender) {
            return format(profileMessages.gender2Missing);
        }
    }, []);
    const dobValidity = useCallback((dob?: Date) => {
        if (curData.type !== EUserType.employer) {
            if (!dob) {
                return format(profileMessages.dobMissing);
            } else {
                if (Date.now() - dob.getTime() < year * 18) {
                    return format(profileMessages.dobToClose);
                }
            }
        }
    }, []);
    const phoneValidity = useCallback((phone: string) => {
        if (!phone) {
            return format(profileMessages.phoneMissingError);
        }
        // } else if (
        //     !phone.match(
        //         /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        //     )
        // ) {
        //     return format(profileMessages.phoneMalformatedError);
        // }
    }, []);
    const natValidity = useCallback((nat: string) => {
        if (!nat && curData.type !== EUserType.employer) {
            return format(profileMessages.nationalityMissingError);
        }
    }, []);
    /**
     * effect to determine validity of inputs
     */
    useEffect(() => {
        const validity =
            fnValidity(curData.firstName) ||
            lnValidity(curData.lastName) ||
            phoneValidity(curData.phone) ||
            (curData.type === EUserType.user &&
                (dobValidity(new Date(curData.dateOfBirth)) ||
                    natValidity(curData.nationality) ||
                    genderValidity(curData.gender)));
        onValidityChange(
            validity ? format(generalMessages.somethingWrong) : '',
        );
    }, [curData]);
    /**
     * render
     */
    return (
        <>
            {!hideDetailedFields && (
                <View style={style.verticalPadded}>
                    <CText>{format(profileMessages.profilePicture)}</CText>
                    <View style={style.verticalPadded}>
                        <View style={{ marginHorizontal: 'auto' }}>
                            <ProfilePicture
                                data={curData}
                                onChange={(picture) => onChange({ picture })}
                                large
                            />
                        </View>
                        <View style={{ marginHorizontal: 'auto' }}>
                            {!!curData.picture && (
                                <View style={style.verticalPadded}>
                                    <CButton
                                        icon="close"
                                        title={format(actionMessages.delete)}
                                        onPress={() =>
                                            onChange({ picture: '' })
                                        }
                                        smaller
                                        minor
                                    />
                                </View>
                            )}
                        </View>
                    </View>
                </View>
            )}
            <View style={[style.verticalPadded]}>
                <CText>{format(profileMessages.mail)}</CText>
                <CText message={curData.mail} />
            </View>
            {curData.type !== EUserType.employer && (
                <>
                    <CText message={format(profileMessages.gender2)} />
                    <Radio
                        onChange={(gender) => onChange({ gender })}
                        value={curData.gender}
                        values={[
                            {
                                label: format(profileMessages.salutationMale),
                                value: EGender.male,
                            },
                            {
                                label: format(profileMessages.salutationFemale),
                                value: EGender.female,
                            },
                        ]}
                        horizontal
                        cy={'gender-radio'}
                    />
                    {genderValidity(curData.gender) && (
                        <CText
                            message={genderValidity(curData.gender)}
                            style={style.error}
                        />
                    )}
                    {/* <CPicker
                        cy={'gender-picker'}
                        title={format(profileMessages.gender)}
                        value={curData.gender}
                        onChange={(gender) => onChange({ gender })}
                        values={[
                            {
                                label: `-${format(
                                    profileMessages.genderPlaceholder,
                                )}-`,
                                value: undefined,
                                hidden: true,
                            },
                            ...(
                                Object.keys(
                                    genderMessages,
                                ) as (keyof typeof genderMessages)[]
                            ).map((v) => {
                                return {
                                    label: format(genderMessages[v]),
                                    value: v,
                                };
                            }),
                        ]}
                        validity={genderValidity(curData.gender)}
                    /> */}
                </>
            )}
            {!hideDetailedFields && curData.profession !== EProfession.nurse && (
                <View style={style.verticalPadded}>
                    <CText>{format(profileMessages.selectTitle)}</CText>
                    <Radio
                        onChange={(title) => onChange({ title })}
                        value={curData.title}
                        values={[
                            {
                                label: format(doctorTitleMessages.noTitle),
                                value: undefined,
                            },
                            {
                                label: format(doctorTitleMessages.doctor),
                                value: 'doctor',
                            },
                            {
                                label: format(doctorTitleMessages.professor),
                                value: 'professor',
                            },
                            {
                                label: format(
                                    doctorTitleMessages.professordoctor,
                                ),
                                value: 'professordoctor',
                            },
                            // ...Object.keys(doctorTitleMessages).map((v) => {
                            //     const parsedV =
                            //         v as keyof typeof doctorTitleMessages;
                            //     return {
                            //         label: format(
                            //             doctorTitleMessages[v as EDoctorTitle],
                            //         ),
                            //         value:
                            //             parsedV !== 'noTitle' ? v : undefined,
                            //     };
                            // }),
                        ]}
                        horizontal
                        cy={'title-radio'}
                    />
                </View>
            )}
            <CTextInput
                cy={'first-name'}
                value={curData.firstName}
                onChangeText={(firstName) => onChange({ firstName })}
                // placeholder={format(profileMessages.firstNamePlaceholder)}
                label={format(profileMessages.firstName)}
                autoExtend
                customCheckInvalidity={fnValidity}
                disabled={!onboarding}
                hint={
                    !onboarding ? format(profileMessages.noNameEdit) : undefined
                }
            />
            <CTextInput
                cy={'last-name'}
                value={curData.lastName}
                onChangeText={(lastName) => onChange({ lastName })}
                // placeholder={format(profileMessages.lastNamePlaceholder)}
                label={format(profileMessages.lastName)}
                autoExtend
                customCheckInvalidity={lnValidity}
                disabled={!onboarding}
                hint={
                    !onboarding ? format(profileMessages.noNameEdit) : undefined
                }
            />
            {curData.type === EUserType.user && (
                <CDatePicker
                    cy={'dob-picker'}
                    value={new Date(curData.dateOfBirth)}
                    onChange={(dob) =>
                        dob && onChange({ dateOfBirth: dob.getTime() })
                    }
                    title={format(profileMessages.dob)}
                    customCheckInvalidity={dobValidity}
                    maxDate={Date.now() - year * 16}
                    minDate={Date.now() - year * 100}
                />
            )}
            <View
                style={[
                    style.horizontal,
                    style.horizontalWrap,
                    { alignItems: 'flex-end' },
                ]}
            >
                <View style={[style.flex1, { minWidth: 300 }]}>
                    <CTextInput
                        cy={'phone'}
                        value={curData.phone}
                        onChangeText={(phone) => onChange({ phone })}
                        placeholder={format(profileMessages.phonePlaceholder)}
                        label={format(profileMessages.phone)}
                        autoExtend
                        customCheckInvalidity={phoneValidity}
                        disabled={!!(isSelfEdit && mfaInfo)}
                    />
                </View>
                {isSelfEdit &&
                    !onboarding &&
                    (!mfaInfo ? (
                        <View style={style.verticalPadded}>
                            <CButton
                                title={profileMessages.setup2FA}
                                onPress={() => {
                                    secureNavigate('/multifactorsetup');
                                }}
                            />
                        </View>
                    ) : (
                        <View style={{ marginBottom: 25 }}>
                            <CIcon icon={'check'} tint={theme.successColor} />
                        </View>
                    ))}
            </View>
            {curData.type === EUserType.user && (
                <CPicker
                    cy={'nationality-picker'}
                    title={format(profileMessages.nationality)}
                    values={[
                        {
                            label: `-${format(
                                profileMessages.nationalityPlaceholder,
                            )}-`,
                            value: undefined,
                            hidden: true,
                        },
                        ...priorisedCountryKeys.map((v) => {
                            return {
                                // @ts-ignore
                                label: format(countryMessages[v]),
                                value: v,
                            };
                        }),
                        {
                            label: `-----------------`,
                            value: undefined,
                            disabled: true,
                        },
                        ...otherCountryKeys.map((v) => {
                            return {
                                // @ts-ignore
                                label: format(countryMessages[v]),
                                value: v,
                            };
                        }),
                    ]}
                    value={curData.nationality}
                    onChange={(nationality) => onChange({ nationality })}
                    validity={natValidity(curData.nationality)}
                />
            )}
            {!onboarding && curData.type === EUserType.user && (
                <View style={style.verticalPadded}>
                    <CPicker
                        title={profileMessages.driversLicense}
                        values={[
                            {
                                value: '',
                                label: profileMessages.noDriversLicense,
                            },
                            ...[
                                'A',
                                'A1',
                                'B',
                                'BE',
                                'C1',
                                'C1E',
                                'C',
                                'CE',
                                'D1',
                                'D1E',
                                'D',
                                'DE',
                            ].map((v) => {
                                return { value: v, label: v };
                            }),
                        ]}
                        multiValues={curData.driversLicense.split(',')}
                        onChangeMulti={(v) => {
                            onChange({ driversLicense: v.join(',') });
                        }}
                        keepOpen
                    />
                    <CCheckBox
                        title={profileMessages.hasVehicle}
                        checked={curData.hasVehicle}
                        onCheckedChanged={(c) => onChange({ hasVehicle: c })}
                    />
                </View>
            )}
            {!onboarding && curData.type !== EUserType.user && (
                <>
                    <CPicker
                        title={profileMessages.restrictiveFields}
                        placeholder={
                            profileMessages.restrictiveFieldsPlaceholder
                        }
                        multiValues={curData.fields}
                        values={Object.values(EField)
                            .filter((v) => v !== EField.undefined)
                            .map((field) => ({
                                value: field,
                                label: fieldMessages[field],
                            }))}
                        onChange={() => {
                            // is always multichanging
                        }}
                        onChangeMulti={(fields) => onChange({ fields })}
                    />
                </>
            )}
        </>
    );
};
