import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { CButton, CCard, CIcon, CText, Spinner } from '../../../components';
import { useFormat } from '../../../utilities/intl';
import { filenameMessages } from '../../../utilities/messages';
import { useSecureNavigate } from '../../../utilities/routing';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ChatView } from '../../Chat';
import { ContractOverviewBar } from '../components/ContractOverviewBar';
import { ContractTitle } from '../components/ContractTitle';
import { SelectSigningEmployer } from '../components/SelectSigningEmployer';
import { contractMessages } from '../contract.messages';
import { ContractFiles } from './components/ContractFiles';
import { useContractViewState } from './useContractViewState';
import { EApplicationType, EContractStatus, EUserType } from '../../../enums';
import { isLocalhost } from '../../../utilities/constants';
import { ScrollProvider } from '../../../utilities/contexts';
import { useDimensions } from '../../../utilities/hooks';
import { BasicContractView } from './BasicContractView';
import { JobOverviewBar } from '../components/JobOverviewBar';
import { ApplicantOverviewBar } from '../components/ApplicantOverviewBar';
import { useFireBase } from '../../../utilities/firebase';
import { jobMessages } from '../../Job/job.messages';
import { ContractSettings } from './components/ContractSettings';
/**
 * enum to declare which overview to show
 */
enum EContractViewModes {
    contractDetails = 'contractDetails',
    employeeDetails = 'employeeDetails',
    jobDetails = 'jobDetails',
    settings = 'settings',
}
/**
 * Contract view
 * - overview over contract values
 * - all documents related to contract
 * - options to upload documents and request a signature
 * - display the chat related to this contract
 * @returns
 */
export const ContractView: FC = () => {
    const style = useStyle();
    const { theme } = useTheme();
    const format = useFormat();
    const { secureNavigate } = useSecureNavigate();
    const {
        contract,
        job,
        application,
        chat,
        timesheetComplete,
        openReview,
        handleSigningEmployerChange,
        preFile,
        handleFile,
        joinChat,
        setContract,
        setReload,
        reload,
        hpw,
    } = useContractViewState();
    const { userData } = useFireBase();
    const { mainHeight } = useDimensions();
    const [containerHeight, setContainerHeight] = useState(0);
    const [mode, setMode] = useState<EContractViewModes>();
    /**
     * return spinner if no contract
     */
    if (!contract) {
        return <Spinner />;
    }
    /**
     * return basic view if type is basic
     */
    if (contract.type === EApplicationType.default) {
        return (
            <BasicContractView
                {...{
                    contract,
                    job,
                    application,
                    chat,
                    timesheetComplete,
                    openReview,
                    handleSigningEmployerChange,
                    preFile,
                    handleFile,
                    joinChat,
                    setContract,
                    setReload,
                    reload,
                    hpw,
                }}
            />
        );
    }
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View
                style={{
                    minHeight: mainHeight - 40,
                }}
            >
                <View
                    style={[
                        style.headlineCard,
                        style.horizontalWrap,
                        style.horizontalSplit,
                    ]}
                >
                    <View
                        style={[style.horizontal, style.centeredItems]}
                        {...{
                            dataSet: { cy: 'contract-view-headline-container' },
                        }}
                    >
                        <CButton
                            cy={'back'}
                            onPress={async () => {
                                secureNavigate(-1);
                            }}
                            icon={'chevronLeft'}
                            small
                        />
                        <View>
                            <ContractTitle
                                contract={contract}
                                horizontalPadded
                            />
                            <View
                                style={[style.horizontal, style.centeredItems]}
                            >
                                <CIcon
                                    icon="calendar"
                                    tint={theme.textSecondaryColor}
                                    size={14}
                                />
                                <CText message={jobMessages.fromTo} />
                                <CText style={style.leftPadded}>
                                    {`${new Date(
                                        contract.from,
                                    ).toLocaleDateString('de')} - ${new Date(
                                        contract.to,
                                    ).toLocaleDateString('de')}`}
                                </CText>
                                {hpw > 0 && (
                                    <CText style={style.leftPadded}>
                                        {`| ${hpw} ${format(
                                            contractMessages.hoursPerWeekUnit,
                                        )}`}
                                    </CText>
                                )}
                            </View>
                        </View>
                    </View>
                    {userData.type !== EUserType.talent && (
                        <View
                            style={style.horizontal}
                            nativeID="detail-buttons"
                        >
                            {Object.values(EContractViewModes).map(
                                (thisMode) => (
                                    <CButton
                                        key={thisMode}
                                        title={
                                            thisMode !== 'settings'
                                                ? contractMessages[thisMode]
                                                : undefined
                                        }
                                        icon={
                                            thisMode !== 'settings'
                                                ? undefined
                                                : 'cog'
                                        }
                                        onPress={() => {
                                            setMode(
                                                mode !== thisMode
                                                    ? thisMode
                                                    : undefined,
                                            );
                                        }}
                                        small
                                        style={style.smallMargin}
                                        minor={mode !== thisMode}
                                    />
                                ),
                            )}
                        </View>
                    )}
                </View>
                {mode === EContractViewModes.contractDetails && (
                    <ContractOverviewBar contract={contract} />
                )}
                {mode === EContractViewModes.jobDetails && job && (
                    <JobOverviewBar job={job} />
                )}
                {mode === EContractViewModes.employeeDetails && application && (
                    <ApplicantOverviewBar
                        application={application}
                        applicant={contract.employee}
                    />
                )}
                {mode === EContractViewModes.settings && (
                    <ContractSettings
                        contract={contract}
                        issueReload={() => setReload(Date.now())}
                    />
                )}
                <SelectSigningEmployer
                    contract={contract}
                    onChange={handleSigningEmployerChange}
                />
                <View style={style.horizontal}>
                    <CCard outsideStyle={{ flex: 1 }}>
                        <CButton
                            title={contractMessages.openTimesheet}
                            onPress={() =>
                                secureNavigate(
                                    `/contract/${contract.documentId}/timesheets`,
                                )
                            }
                            icon={'clipboardAccount'}
                        />
                    </CCard>
                    {userData.type !== EUserType.talent && (
                        <CCard outsideStyle={{ flex: 1 }}>
                            <CButton
                                title={contractMessages.provideReview}
                                onPress={openReview}
                                icon={'star'}
                                disabled={
                                    !isLocalhost() &&
                                    contract.status !==
                                        EContractStatus.completed
                                }
                            />
                        </CCard>
                    )}
                </View>
                {userData.type !== EUserType.talent && (
                    <View style={style.card}>
                        <CText message={contractMessages.mainInfoText} />
                        {[
                            filenameMessages.leasingContract,
                            filenameMessages.equalPayDeclaration,
                        ].map((m, i) => (
                            <CText
                                key={m.id}
                                message={`${i + 1}. ${format(m)}`}
                            />
                        ))}
                        <View style={style.paddedThinSeparator} />
                        <CText message={contractMessages.secondaryInfoText} />
                        {[
                            filenameMessages.houseRules,
                            filenameMessages.locationPlan,
                            filenameMessages.contactDetails,
                            filenameMessages.educationCertificate,
                            filenameMessages.driversLicense,
                            filenameMessages.timesheetTemplate,
                        ].map((m, i) => (
                            <CText
                                key={m.id}
                                message={`${i + 1}. ${format(m)}`}
                            />
                        ))}
                    </View>
                )}
                <View
                    style={[style.horizontal, style.flex1]}
                    onLayout={(e) =>
                        setContainerHeight(e.nativeEvent.layout.height)
                    }
                >
                    <View style={[style.flex1]}>
                        <View
                            style={{
                                height: containerHeight - 20,
                            }}
                            nativeID="contract-documents"
                        >
                            <ContractFiles
                                contract={contract}
                                maxHeight={containerHeight - 20}
                            />
                        </View>
                    </View>
                    {!!chat ? (
                        <View
                            style={[style.card, style.flex1]}
                            nativeID="contract-chat"
                        >
                            <ChatView
                                chat={chat}
                                handleFile={handleFile}
                                preFile={preFile}
                                embedded
                            />
                        </View>
                    ) : (
                        <View
                            style={[style.card, style.flex1]}
                            nativeID="contract-chat"
                        >
                            <CText
                                message={
                                    contractMessages.youAreNotParticipatingInThisChat
                                }
                                centered
                            />
                            <View
                                style={[
                                    style.verticalPadded,
                                    style.horizontalSpaced,
                                ]}
                            >
                                <CButton
                                    onPress={joinChat}
                                    title={contractMessages.joinChat}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </ScrollProvider>
    );
};
