import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from '../../../utilities/routing';
import { MApplication, MContract, MJob } from '../../../models';
import { useFireBase } from '../../../utilities/firebase';
import { useTheme } from '../../../utilities/styles';
import { IFilter } from '../../../utilities/firebase/store';
import { actionMessages } from '../../../utilities/messages';
import {
    EApplicationStatus,
    ECollections,
    EContractStatus,
    EJobStatus,
} from '../../../enums';
import { jobApplicationMessages } from '../JobApplication.messages';
import { useDialog } from '../../../utilities/dialog';
import { jobMessages } from '../../Job/job.messages';
import { useFormat } from '../../../utilities/intl';
import { useLock } from '../../../utilities/hooks';

export const useJobApplicationViewState = () => {
    // global state
    const navigate = useNavigate();
    const {
        callFunction,
        getDataIndex,
        userData,
        getDataById,
        userWorkplaces,
    } = useFireBase();
    const { lock } = useLock();
    const format = useFormat();
    const { theme } = useTheme();
    const dialog = useDialog();
    const { id } = useParams<{ id: string }>();
    // local state
    const [job, setJob] = useState<MJob>();
    const [applications, setApplications] = useState<MApplication[]>([]);
    const [openContracts, setOpenContracts] = useState<MContract[]>([]);
    const [activeNegotiations, setActiveNegotiations] = useState<MContract[]>(
        [],
    );
    const [hasOpenNegotiation, setHasOpenNegotiation] = useState(0);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState<string>('rate');
    /**
     * memoized ordered applications
     */
    const orderedApplications = useMemo(() => {
        if (orderBy === 'rate') {
            return applications.sort((a, b) => {
                const vA = (a.wage.wage || 0) + (a.wage.regularWage || 0);
                const vB = (b.wage.wage || 0) + (b.wage.regularWage || 0);
                return vB - vA;
            });
        } else if (orderBy === 'yearsOfExperience') {
            return applications.sort(
                (a, b) => b.yearsOfExperience - a.yearsOfExperience,
            );
        }
        return applications;
    }, [applications, orderBy]);
    /**
     * preferredApplications
     */
    const orderedApplicationsPreferred = useMemo(() => {
        const preferredAgencies = userWorkplaces.reduce((acc, wp) => {
            acc.push(...wp.agencies);
            return Array.from(new Set(acc));
        }, [] as string[]);
        return orderedApplications.filter((application) => {
            return (
                !!application.agencyId &&
                preferredAgencies.includes(application.agencyId)
            );
        });
    }, [userWorkplaces, orderedApplications]);
    /**
     * the regular Applications
     * filtered based on preferred list
     */
    const orderedApplicationsRegular = useMemo(
        () =>
            orderedApplications.filter(
                (a) =>
                    !orderedApplicationsPreferred.find(
                        (b) => b.documentId === a.documentId,
                    ),
            ),
        [orderedApplicationsPreferred, orderedApplications],
    );
    /**
     * effect to load applications
     */
    useEffect(() => {
        if (!userData.documentId || !job) {
            return;
        }
        const filter: IFilter[] = [];
        filter.push(
            { field: 'jobId', value: job.documentId },
            {
                field: 'status',
                operator: 'in',
                value: [
                    EApplicationStatus.open,
                    EApplicationStatus.negotiating,
                ],
            },
            // TODO: remove this restriction and receive access by being connected to the workplace
            { field: 'jobWorkplace', value: job.workPlaceId },
        );
        /**
         * get applications
         */
        getDataIndex(ECollections.applications, { filter })
            .then((result) => {
                setApplications(
                    (result as MApplication[]).map((r) => new MApplication(r)),
                );
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
        /**
         * check for open contracts or negotiations
         */
        getDataIndex(ECollections.contracts, {
            filter: [
                { field: 'jobId', value: job.documentId },
                { field: 'workplaceId', value: job.workPlaceId },
            ],
        }).then((res) => {
            const next = (res as any[]).map((r) => new MContract(r));
            if (next.length) {
                setHasOpenNegotiation(res as number);
            }
            setOpenContracts(
                next.filter((n) => n.status === EContractStatus.signed),
            );
            setActiveNegotiations(
                next.filter((n) =>
                    [
                        EContractStatus.review_yee,
                        EContractStatus.review_yer,
                        EContractStatus.accepted,
                        EContractStatus.creating,
                    ].includes(n.status),
                ),
            );
        });
    }, [userData, job]);
    /**
     * effect to load job
     */
    useEffect(() => {
        if (id) {
            getDataById(ECollections.jobs, id).then((v) => setJob(new MJob(v)));
        }
    }, [id]);
    /**
     * handle accept
     * @param application
     * @param status
     */
    const handleStatusChange = useCallback(
        async (application: MApplication, nextStatus: EApplicationStatus) => {
            let completeJob = false;

            if (
                nextStatus !== EApplicationStatus.rejected &&
                hasOpenNegotiation >= 3
            ) {
                return dialog({
                    title: jobApplicationMessages.noMoreThanThreeNegotiations,
                    message:
                        jobApplicationMessages.noMoreThanThreeNegotiationsMessage,
                    buttons: [
                        {
                            text: actionMessages.ok,
                            color: theme.accentColor,
                        },
                    ],
                    icon: 'error',
                });
            }
            /**
             * reject if not confirm rejection
             */
            if (
                nextStatus === EApplicationStatus.rejected &&
                !(await dialog({
                    title: jobApplicationMessages.confimApplicationReject,
                    message: jobApplicationMessages.confimApplicationRejectText,
                    buttons: [
                        {
                            text: actionMessages.ok,
                            color: theme.accentColor,
                        },
                    ],
                    cancelButton: {
                        text: actionMessages.cancel,
                    },
                    icon: 'question',
                }))
            ) {
                return;
            }
            /**
             * reject if not confirmed there is an open negotiation
             */
            if (
                nextStatus !== EApplicationStatus.rejected &&
                hasOpenNegotiation &&
                !(await dialog({
                    title: jobApplicationMessages.thereIsAnOpenNegotiation,
                    message:
                        jobApplicationMessages.thereIsAnOpenNegotiationMessage,
                    buttons: [
                        {
                            text: actionMessages.ok,
                            color: theme.accentColor,
                        },
                    ],
                    cancelButton: {
                        text: actionMessages.cancel,
                    },
                    icon: 'question',
                }))
            ) {
                return;
            }
            /**
             * reject if not awnswered dialog
             */
            if (
                nextStatus !== EApplicationStatus.rejected &&
                !(await dialog({
                    title: jobApplicationMessages.completeJob,
                    message: jobApplicationMessages.completeJobDescription,
                    buttons: [
                        {
                            text: jobApplicationMessages.acceptAndClose,
                            color: theme.accentColor,
                            onPress: () => {
                                completeJob = true;
                            },
                        },
                        {
                            text: jobApplicationMessages.justAccept,
                            color: theme.successColor,
                        },
                    ],
                    verticalButtons: true,
                    icon: 'question',
                    cancelButton: {
                        text: actionMessages.cancel,
                        color: theme.errorColor,
                    },
                }))
            ) {
                return;
            }
            /**
             * call function to handle change
             */
            const response = await callFunction('changeApplicationStatus', {
                applicationId: application.documentId,
                nextStatus,
                completeJob,
            });
            if (response === 'invalid') {
                console.error('Something unexpected happened');
            } else if (nextStatus === EApplicationStatus.rejected) {
                setApplications((v) => {
                    const i = v.indexOf(application);
                    const next = [...v];
                    next.splice(i, 1);
                    return next;
                });
            } else if (nextStatus === EApplicationStatus.accepted) {
                if (response) {
                    navigate('/negotiations/' + response);
                }
            }
        },
        [callFunction, navigate, hasOpenNegotiation],
    );
    /**
     * callback to share job via mail
     */
    const share = useCallback(() => {
        dialog({
            title: jobMessages.shareJob,
            message: jobMessages.shareJobText,
            textInputs: [
                {
                    title: jobMessages.recipientPlaceholder,
                    id: 'mail',
                },
            ],
            buttons: [
                {
                    text: actionMessages.share,
                    onPress: (inputs) => {
                        const mail = `${
                            inputs?.find((v) => v.id === 'mail')?.value || ''
                        }`;
                        const unlock = lock();
                        callFunction('sendJobMail', {
                            jobId: job?.documentId,
                            mail,
                        }).then(() => {
                            const emailAdrCount = mail
                                .split(/[\s,;]+/)
                                .filter((t) => !!t)
                                .map((email) => ({ email })).length;
                            dialog({
                                icon: 'success',
                                title: jobMessages.sharedJob,
                                message: format(jobMessages.sharedJobText, {
                                    x: emailAdrCount,
                                }),
                            }).then(unlock);
                        });
                    },
                },
            ],
            cancelButton: { text: actionMessages.cancel },
        });
    }, [job, dialog]);
    /**
     * callback to close job and reject all applications
     */
    const closeJob = useCallback(async () => {
        if (!job) return;
        if (
            await dialog({
                icon: 'question',
                title: jobApplicationMessages.closeJobDialog,
                message: jobApplicationMessages.closeJobDialogText,
                buttons: [
                    {
                        text: jobApplicationMessages.closeJob,
                        color: theme.errorColor,
                    },
                ],
                cancelButton: { text: actionMessages.cancel },
            })
        ) {
            const unlock = lock();
            await callFunction('closeJob', {
                jobId: job.documentId,
                status: EJobStatus.disabled,
            });
            unlock();
            navigate(-1);
        }
    }, [job, dialog]);
    /**
     * render
     */
    return {
        loading,
        openContracts,
        activeNegotiations,
        orderedApplications,
        orderedApplicationsPreferred,
        orderedApplicationsRegular,
        handleStatusChange,
        job,
        orderBy,
        setOrderBy,
        share,
        closeJob,
    };
};
