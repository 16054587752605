import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    CButton,
    CCheckBox,
    CPicker,
    CTextInput,
} from '../../../../components';
import { contractMessages } from '../../contract.messages';
import { MContract } from '../../../../models';
import { ETimesheetType } from '../../../../enums/ETImesheetType';
import { useStyle } from '../../../../utilities/styles';
import { useFireBase } from '../../../../utilities/firebase';
import { useLock } from '../../../../utilities/hooks';
import {
    isAgencyUser,
    isEmployer,
    isPeasant,
    isSuperUser,
} from '../../../../utilities/auth';
import { actionMessages } from '../../../../utilities/messages';

export const ContractSettings: FC<{
    contract: MContract;
    issueReload: () => void;
}> = ({ contract, issueReload }) => {
    const style = useStyle();
    const { lock } = useLock();
    const { callFunction, userData } = useFireBase();

    const [leadMail, setLeadMail] = useState(contract.workplaceLeadMail);
    const [leadName, setLeadName] = useState(contract.workplaceLeadMail);

    const hasChanges = useMemo(
        () =>
            leadMail !== contract.workplaceLeadMail ||
            leadName !== contract.workplaceLeadName,
        [leadMail, leadName, contract],
    );

    const handleSave = useCallback(async () => {
        const unlock = await lock();
        try {
            await callFunction('updateContractSettings', {
                contractId: contract.documentId,
                workplaceLeadMail: leadMail,
                workplaceLeadName: leadName,
            });
            issueReload();
            unlock();
        } catch (e) {
            unlock();
        }
    }, [contract, leadMail, leadName]);

    const onChange = useCallback(
        async (timesheetType: ETimesheetType, alerts?: boolean) => {
            const unlock = await lock();
            try {
                await callFunction('updateContractSettings', {
                    contractId: contract.documentId,
                    timesheetType,
                    timesheetAlerts: alerts,
                });
                issueReload();
                unlock();
            } catch (e) {
                unlock();
            }
        },
        [contract, lock, issueReload],
    );
    /**
     * render
     */
    return (
        <View style={style.card}>
            {isEmployer(userData) && (
                <View>
                    <CTextInput
                        label={contractMessages.leadMail}
                        value={leadMail}
                        onChangeText={setLeadMail}
                    />
                    <CTextInput
                        label={contractMessages.leadName}
                        value={leadName}
                        onChangeText={setLeadName}
                    />
                    <CButton
                        title={actionMessages.save}
                        disabled={!hasChanges}
                        onPress={handleSave}
                    />
                </View>
            )}
            <CPicker
                title={contractMessages.timesheetInterval}
                values={[
                    {
                        value: ETimesheetType.montly,
                        label: contractMessages.timesheetIntervalMonthly,
                    },
                    {
                        value: ETimesheetType.weekly,
                        label: contractMessages.timesheetIntervalWeekly,
                    },
                    {
                        value: ETimesheetType.undefined,
                        label: contractMessages.timesheetIntervalUndefined,
                    },
                ]}
                value={contract.timesheetType}
                onChange={(next) => {
                    onChange(next, contract.timesheetAlerts);
                }}
            />
            {(isPeasant(userData) || isSuperUser(userData)) && (
                <CCheckBox
                    title={contractMessages.timesheetAlerts}
                    checked={contract.timesheetAlerts}
                    onCheckedChanged={(c) =>
                        onChange(contract.timesheetType, c)
                    }
                />
            )}
        </View>
    );
};
