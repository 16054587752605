import React, { FC, useContext, useEffect, useState } from 'react';
import { useStyle } from '../../../utilities/styles';
import { View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import {
    EducationPicker,
    FieldPicker,
    ProfessionPicker,
} from '../../../components/Pickers';
import { EField, EProfession } from '../../../enums';
import { onboardingMessages } from '../Onboarding.messages';
import { CText, InfoBox } from '../../../components';
import { fieldMessages, generalMessages } from '../../../utilities/messages';
import { ProfileContext } from '../../Profile/Profile.context';

interface IAgencyProfileCreateProps {
    onChangeProfessions: (
        professions: {
            profession: EProfession;
            field: EField;
            educations: string[];
        }[],
    ) => void;
}
/**
 * onboarding to pre create professional profiles
 * @param param0 props
 * @returns component
 */
export const AgencyProfileCreate: FC<IAgencyProfileCreateProps> = ({
    onChangeProfessions,
}) => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { curData, onValidityChange } = useContext(ProfileContext);
    const [currentField, setCurrentField] = useState<EField>();
    const [professions, setProfessions] = useState<
        { profession: EProfession; field: EField; educations: string[] }[]
    >([]);
    /**
     * effect to clear validity
     */
    useEffect(() => {
        onValidityChange('');
    }, []);
    /**
     * effect to pass back current selection
     */
    useEffect(() => {
        onChangeProfessions(professions);
    }, [professions]);
    /**
     * render
     */
    return (
        <>
            <View style={style.verticalPadded}>
                <InfoBox
                    type={'info'}
                    message={onboardingMessages.profProfileCreation}
                />
                <FieldPicker
                    fields={curData.fields}
                    onChange={(field) => {
                        setCurrentField(field);
                    }}
                    value={currentField}
                />
                {currentField && (
                    <ProfessionPicker
                        titleOverride={
                            !!professions.length
                                ? format(
                                      onboardingMessages.additionalProfessions,
                                  )
                                : undefined
                        }
                        onChange={(p) => {
                            if (p) {
                                professions.push({
                                    profession: p,
                                    educations: [],
                                    field: currentField,
                                });
                                setProfessions([...professions]);
                            }
                        }}
                        optionsFilter={professions.map((v) => v.profession)}
                        field={currentField}
                        placeholder={
                            <CText>
                                {format(
                                    onboardingMessages.noMoreProfessionsForField,
                                )}
                            </CText>
                        }
                    />
                )}
            </View>
            {!!professions.length && (
                <CText
                    secondaryHeadline
                    message={onboardingMessages.yourTalents}
                />
            )}
            {professions.map(({ profession, educations, field }, index) => {
                return (
                    <View style={style.verticalPadded} key={index}>
                        <View style={[style.thinSeparator]} />
                        <View style={[style.verticalPadded]} />
                        <CText
                            bold
                            secondaryHeadline
                            message={
                                profession !== EProfession.undefined
                                    ? generalMessages[profession]
                                    : fieldMessages[field]
                            }
                        />
                        <EducationPicker
                            ignoreMultiLimit
                            multi
                            field={field}
                            profession={profession}
                            values={educations}
                            onChange={(e) => {
                                professions.splice(index, 1, {
                                    profession,
                                    educations: [...e],
                                    field: field,
                                });
                                setProfessions([...professions]);
                            }}
                        />
                    </View>
                );
            })}
        </>
    );
};
