import { ETimesheetType } from '../enums/ETImesheetType';

export class MFromTo {
    public from = 0;
    public fromHour = 0;
    public fromMinute = 0;
    public to = 0;
    public toHour = 0;
    public toMinute = 0;

    constructor(params: Partial<MFromTo> = {}) {
        Object.assign(this, params);
    }
}

export class MShift extends MFromTo {
    /**
     * document id will be set from firebase on load
     */
    public documentId = '';

    public timesheetId = '';

    public date = '';
    public month = '';
    public year = '';
    public complete = false;
    public breaks: MFromTo[] = [];
    public note = '';

    public geolocation?: {
        latitude: number;
        longitude: number;
    };

    constructor(params: Partial<MShift> = {}) {
        super(params);
        Object.assign(this, params);

        if (params.breaks) {
            this.breaks = params.breaks.map((b) => new MFromTo(b));
        }
    }
}

export class MTimesheet {
    /**
     * document id will be set from firebase on load
     */
    public documentId = '';
    public type = ETimesheetType.undefined;

    public complete = false;

    public week?: number;
    public month?: number;
    public year?: number;
    public contractId?: string;
    public workplaceId?: string;
    public employeeId?: string;
    public agencyId?: string;

    public isAgency = false;

    public exposed = false;
    /**
     * file id of generated timesheet file
     * (contracts/{contractId}/files/{fileId} for contract timesheets)
     */
    public fileId?: string;

    public createdOn = Date.now();
    public editedOn = Date.now();

    constructor(params: Partial<MTimesheet> = {}) {
        Object.assign(this, params);
    }
}
