import React, {
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { CText, Spinner } from '../../components';
import { ProgressBar } from '../../components/Progress';
import { CButton } from '../../components/elements';
import {
    ECollections,
    ECountries,
    EField,
    EInviteType,
    EProfession,
    ERegion,
    EUserType,
} from '../../enums';
import {
    MAgency,
    MProfessionalProfile,
    MUserData,
    MWorkplace,
} from '../../models';
import { useEnvironment } from '../../utilities/contexts';
import { useDialog } from '../../utilities/dialog';
import { useFireBase } from '../../utilities/firebase';
import { year } from '../../utilities/functions';
import { useDimensions } from '../../utilities/hooks/useDimensions';
import { LanguageContext, useFormat } from '../../utilities/intl';
import { actionMessages } from '../../utilities/messages/action.messages';
import { useStyle } from '../../utilities/styles';
import { ProfessionalLanguage } from '../ProfProfile/Edit/components/ProfessionalLanguage';
import { ProfileContext } from '../Profile/Profile.context';
import { profileMessages } from '../Profile/Profile.messages';
import { ProfileSectionAddress } from '../Profile/components/PSAddress';
import { ProfileSectionBasic } from '../Profile/components/PSBasic';
import { ProfileSectionPayment } from '../Profile/components/PSPayment';
import { onboardingMessages } from './Onboarding.messages';
import { AgencyInformation } from './components/AgencyInformation';
import { AgencyProfileCreate } from './components/AgencyProfileCreate';
import { HowDidYouFindOut } from './components/HowDidYouFindOut';
import { Preselect } from './components/Preselect';
import { TermsAndConditions } from './components/TermsAndConditions';
import { WorkplaceInformation } from './components/WorkplaceInformation';
import { useInvite } from '../../utilities/hooks/useInvite';
import { FieldInfo } from './components/FieldInfo';
import { useSafeAreaFrame } from 'react-native-safe-area-context';
import { useSecureNavigate } from '../../utilities/routing';

export enum EOnboardingSteps {
    preselect = 'preselect',
    basicInfo = 'basicInfo',
    addressInfo = 'addressInfo',
    paymentInfo = 'paymentInfo',
    languageInfo = 'languageInfo',
    agencyInfo = 'agencyInfo',
    workplaceInfo = 'workplaceInfo',
    fieldInfo = 'fieldInfo',
    marketingInfo = 'marketingInfo',
    termsAndConditions = 'termsAndConditions',
    agencyProfileCreate = 'agencyProfileCreate',
}

export const Onboarding: FC = () => {
    // global state
    const style = useStyle();
    const { height } = useDimensions();
    const { height: safeFrameHeight } = useSafeAreaFrame();
    const { region, environment } = useEnvironment();
    const { language } = useContext(LanguageContext);
    const { clearNavigationLock } = useSecureNavigate();
    const format = useFormat();
    const {
        user,
        userData: prevData,
        put,
        post,
        reloadUserData,
        callFunction,
        getDataIndex,
    } = useFireBase();
    const dialog = useDialog();
    // local state
    const invite = useInvite();
    const [curData, setCurData] = useState(
        new MUserData({
            documentId: user?.uid || '',
            dateOfBirth: Date.now() - year * 18,
            createdOn: Date.now(),
            mail: user?.email || undefined,
            preferredLanguage: language,
        }),
    );
    const [agency, setAgency] = useState('');
    const [workplace, setWorkplace] = useState('');
    const [curValidity, setCurValidity] = useState('');
    const [steps, setSteps] = useState<EOnboardingSteps[]>([]);
    const [completedSteps, setCompletedSteps] = useState<EOnboardingSteps[]>(
        [],
    );
    const [loading, setLoading] = useState(false);
    /**
     * professions for prof profile create for agencies
     */
    const [professions, setProfessions] = useState<
        { profession: EProfession; field: EField; educations: string[] }[]
    >([]);
    /**
     * current step
     */
    const step = useMemo(() => {
        return steps.find((step) => !completedSteps.includes(step));
    }, [steps, completedSteps]);
    /**
     * title of current step
     */
    const title = useMemo(() => {
        switch (step) {
            case EOnboardingSteps.basicInfo:
                return format(onboardingMessages.onboardingPersonal);
            case EOnboardingSteps.termsAndConditions:
                return format(onboardingMessages.termsAndConditions);
            case EOnboardingSteps.addressInfo:
                return format(profileMessages.addressInfo);
            case EOnboardingSteps.paymentInfo:
                return format(onboardingMessages.onboardingPayment);
            case EOnboardingSteps.languageInfo:
                return format(onboardingMessages.onboardingLanguage);
            case EOnboardingSteps.marketingInfo:
                return format(onboardingMessages.howDidYouFindOut, {
                    platform: environment,
                });
            case EOnboardingSteps.workplaceInfo:
                return format(onboardingMessages.workplaceName);
            case EOnboardingSteps.agencyInfo:
                return format(onboardingMessages.agencyData);
            case EOnboardingSteps.agencyProfileCreate:
                return format(onboardingMessages.agencyProfileCreate);
            case EOnboardingSteps.fieldInfo:
                return format(onboardingMessages.fieldInfo);
        }
    }, [step, environment]);
    /**
     * handle changes
     */
    const onChange = useCallback(
        (next: Partial<MUserData>) => {
            setCurData((prev) => {
                return { ...prev, ...next, region };
            });
        },
        [region],
    );
    /**
     * callback to handle continue action
     */
    const handleContinue = useCallback(async () => {
        if (!user || !step) {
            return console.error('unexpected access to this function');
        }
        setLoading(true);
        if (
            ![
                EOnboardingSteps.workplaceInfo,
                EOnboardingSteps.agencyInfo,
                EOnboardingSteps.fieldInfo,
            ].includes(step)
        ) {
            await put(ECollections.users, user.uid, curData);
        }
        const next = [...completedSteps];
        next.push(step);
        /**
         * set language from nationality
         * insert language step if not
         * set from nationality
         */
        if (
            step === EOnboardingSteps.basicInfo &&
            curData.type === EUserType.user
        ) {
            if (
                region === ERegion.de &&
                curData.nationality === ECountries.germany
            ) {
                setCurData({
                    ...curData,
                    languages: [{ lang: 'german', level: 'c1' }],
                });
            } else {
                setSteps((prev) => {
                    prev.splice(
                        prev.indexOf(step),
                        0,
                        EOnboardingSteps.languageInfo,
                    );
                    return [...prev];
                });
            }
        }
        if (next.length !== steps.length) {
            setCompletedSteps(next);
        } else {
            // ONBOARDING COMPLETE
            if (user) {
                if (invite && invite.type !== EInviteType.agencyReferral) {
                    callFunction('verifyFromInvite', {
                        inviteId: invite.documentId,
                        targedId: invite.targetId,
                        type: invite.type,
                    }).then((v) => console.log(v));
                }
                callFunction('emailVerify', {
                    mail: user.email,
                });
                if (curData.type === EUserType.employer) {
                    const prev = await getDataIndex(ECollections.workplaces, {
                        filter: [
                            {
                                field: 'users',
                                operator: 'array-contains',
                                value: user.uid,
                            },
                        ],
                    });
                    if (!prev || !(prev as any[]).length) {
                        const wp = new MWorkplace({
                            address: curData.address,
                            billingAddressDiffers:
                                curData.billingAddressDiffers,
                            billingAddress: curData.billingAddress,
                            mail: curData.mail,
                            owner: user.uid,
                            users: [user.uid],
                            name: workplace,
                            phone: curData.phone,
                            region: region,
                        });
                        post(ECollections.workplaces, wp);
                    }
                } else if (curData.type === EUserType.agency) {
                    const prev = await getDataIndex(ECollections.agencies, {
                        filter: [
                            {
                                field: 'users',
                                operator: 'array-contains',
                                value: user.uid,
                            },
                        ],
                    });
                    if (!prev || !(prev as any[]).length) {
                        const agencyToPost = new MAgency({
                            address: curData.address,
                            billingAddressDiffers:
                                curData.billingAddressDiffers,
                            billingAddress: curData.billingAddress,
                            taxNumber: curData.taxNumber,
                            registerNumber: curData.registerNumber,
                            bankDetails: curData.bankDetails,
                            owner: user.uid,
                            users: [user.uid],
                            name: agency,
                            region: region,
                        });
                        const agencyPostResponse = await post(
                            ECollections.agencies,
                            agencyToPost,
                        );

                        if (
                            invite &&
                            invite.type === EInviteType.agencyReferral
                        ) {
                            callFunction('verifyFromInvite', {
                                inviteId: invite.documentId,
                                targedId: invite.targetId,
                                type: invite.type,
                            }).then((v) => console.log(v));
                        }
                        if (professions.length && agencyPostResponse) {
                            professions.forEach(
                                ({ profession, field, educations }) => {
                                    educations.forEach((education) => {
                                        post(
                                            ECollections.profProfiles,
                                            new MProfessionalProfile({
                                                field,
                                                profession,
                                                educations: [education],
                                                author: user.uid,
                                                moderators: [user.uid],
                                                agencyId: agencyPostResponse.id,
                                            }),
                                        );
                                    });
                                },
                            );
                        }
                    }
                }
            }
            await dialog({
                title: format(onboardingMessages.complete),
                message: format(onboardingMessages.completeBody),
                buttons: [
                    {
                        text: format(actionMessages.continue),
                        onPress: () => {
                            clearNavigationLock();
                            reloadUserData();
                        },
                    },
                ],
                icon: 'success',
            });
        }
        setLoading(false);
    }, [
        user,
        invite,
        curData,
        steps,
        completedSteps,
        step,
        callFunction,
        dialog,
        professions,
        region,
        workplace,
        agency,
    ]);
    /**
     * callback to handle back action
     */
    const handleBack = useCallback(() => {
        const next = [...completedSteps];
        if (next.length) {
            next.pop();
            setCompletedSteps(next);
        } else {
            setSteps([]);
            setCompletedSteps([]);
        }
    }, [completedSteps]);
    /**
     * preselect to determin which steps will be upcomming
     */
    if (!step) {
        return (
            <ScrollView
                style={[
                    style.outsideContainer,
                    {
                        height:
                            Platform.OS === 'web' ? height : safeFrameHeight,
                    },
                ]}
            >
                <View style={style.insideContainerNoBG}>
                    <Preselect
                        onChange={onChange}
                        setSteps={setSteps}
                        invite={invite}
                    />
                </View>
            </ScrollView>
        );
    }
    /**
     * render
     */
    return (
        <ProfileContext.Provider
            value={{
                curData,
                prevData,
                onChange,
                onValidityChange: setCurValidity,
                onboarding: true,
            }}
        >
            <ScrollView style={[style.outsideContainer, { height }]}>
                <View style={style.insideContainerNoBG}>
                    <View style={style.verticalPadded}>
                        <ProgressBar
                            percentage={completedSteps.length / steps.length}
                        />
                    </View>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <>
                            <View style={style.horizontalSplit}>
                                <CText
                                    bold
                                    headline
                                    style={style.verticalPadded}
                                    message={title}
                                />
                                <View />
                            </View>
                            <View style={style.thinSeparator} />
                            {step === EOnboardingSteps.basicInfo && (
                                <ProfileSectionBasic />
                            )}
                            {step === EOnboardingSteps.termsAndConditions && (
                                <TermsAndConditions />
                            )}
                            {step === EOnboardingSteps.addressInfo && (
                                <ProfileSectionAddress />
                            )}
                            {step === EOnboardingSteps.paymentInfo && (
                                <ProfileSectionPayment />
                            )}
                            {step === EOnboardingSteps.languageInfo && (
                                <ProfessionalLanguage />
                            )}
                            {step === EOnboardingSteps.marketingInfo && (
                                <HowDidYouFindOut />
                            )}
                            {step === EOnboardingSteps.workplaceInfo && (
                                <WorkplaceInformation
                                    workplace={workplace}
                                    setWorkplace={setWorkplace}
                                />
                            )}
                            {step === EOnboardingSteps.agencyInfo && (
                                <AgencyInformation
                                    agency={agency}
                                    setAgency={setAgency}
                                />
                            )}
                            {step === EOnboardingSteps.agencyProfileCreate && (
                                <AgencyProfileCreate
                                    onChangeProfessions={setProfessions}
                                />
                            )}
                            {step === EOnboardingSteps.fieldInfo && (
                                <FieldInfo />
                            )}
                            <View style={style.verticalPadded}>
                                {!!curValidity && (
                                    <View style={{ marginHorizontal: 'auto' }}>
                                        <CText style={style.error}>
                                            {curValidity}
                                        </CText>
                                    </View>
                                )}
                                <View
                                    style={[
                                        style.verticalPadded,
                                        style.horizontalSpaced,
                                    ]}
                                >
                                    <CButton
                                        title={format(actionMessages.back)}
                                        onPress={handleBack}
                                        minor
                                    />
                                    <CButton
                                        cy={'continue-onboarding'}
                                        title={format(actionMessages.continue)}
                                        disabled={!!curValidity}
                                        onPress={handleContinue}
                                    />
                                </View>
                            </View>
                        </>
                    )}
                </View>
            </ScrollView>
        </ProfileContext.Provider>
    );
};
