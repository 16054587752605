import { defineMessages } from 'react-intl';

const scope = 'con.user.';

export const userMessages = defineMessages({
    view: {
        id: scope + 'view',
        defaultMessage: 'View or edit user',
    },
    verify: {
        id: scope + 'verify',
        defaultMessage: 'Verify',
    },
    verified: {
        id: scope + 'verified',
        defaultMessage: 'Verified',
    },
    unverified: {
        id: scope + 'unverified',
        defaultMessage: 'Unverified',
    },
    verifiedOn: {
        id: scope + 'verifiedOn',
        defaultMessage: 'Verified on',
    },
    verifiedFrom: {
        id: scope + 'verifiedFrom',
        defaultMessage: 'Verified from',
    },
    createdOn: {
        id: scope + 'createdOn',
        defaultMessage: 'Created on',
    },
    editNote: {
        id: scope + 'editNote',
        defaultMessage: 'Edit note',
    },
    finishEditing: {
        id: scope + 'finishEditing',
        defaultMessage: 'Finish editing',
    },
    doYouWantToUnverifyX: {
        id: scope + 'doYouWantToUnverifyX',
        defaultMessage: 'Do you want to REMOVE VERIFICATION of user {x}',
    },
    doYouWantToVerifyX: {
        id: scope + 'doYouWantToVerifyX',
        defaultMessage: 'Do you want to VERIFY user {x}',
    },
    confirmVerificationStatusChange: {
        id: scope + 'confirmVerificationStatusChange',
        defaultMessage: 'You are about to change the verification status',
    },
    verifiedByMe: {
        id: scope + 'verifiedByMe',
        defaultMessage: 'My users',
    },
    userTypePlaceholder: {
        id: scope + 'userTypePlaceholder',
        defaultMessage: 'Select user type',
    },
    today: {
        id: scope + 'today',
        defaultMessage: 'Last 24h',
    },
    setExtraAvailable: {
        id: scope + 'setExtraAvailable',
        defaultMessage: 'Set extra available',
    },
    unSetExtraAvailable: {
        id: scope + 'unSetExtraAvailable',
        defaultMessage: 'Unset extra available',
    },
    extra: {
        id: scope + 'extra',
        defaultMessage: 'Flexible',
    },
    assignedAgents: {
        id: scope + 'assignedAgents',
        defaultMessage: 'Assigned agents',
    },
    selectAgent: {
        id: scope + 'selectAgent',
        defaultMessage: 'Select agents',
    },
    assignedAgent: {
        id: scope + 'assignedAgent',
        defaultMessage: 'Primary agent:',
    },
    confirmNotificationSendTitle: {
        id: scope + 'confirmNotificationSendTitle',
        defaultMessage: 'You are about to send a notification to a user.',
    },
    confirmNotificationSendMessage: {
        id: scope + 'confirmNotificationSendMessage',
        defaultMessage:
            'You are about to send a notification to {mail}. Do you want to proceed?',
    },
    disabled: {
        id: scope + 'disabled',
        defaultMessage: 'Disabled',
    },
    disableUser: {
        id: scope + 'disableUser',
        defaultMessage: 'Disable user',
    },
    enableUser: {
        id: scope + 'enableUser',
        defaultMessage: 'Enable user',
    },
    changingDisabledStatus: {
        id: scope + 'changingDisabledStatus',
        defaultMessage: 'You are changing the disabled status of a user',
    },
    changingDisabledStatusText: {
        id: scope + 'changingDisabledStatusText',
        defaultMessage: 'Do you want to proceed changing the status?',
    },
    changingExtraAvailable: {
        id: scope + 'changingExtraAvailable',
        defaultMessage:
            'You are changing the extra available mark for this user',
    },
    changingExtraAvailableText: {
        id: scope + 'changingExtraAvailableText',
        defaultMessage:
            'Do you want to proceed changing the status for this user?',
    },
    lastName: {
        id: scope + 'lastName',
        defaultMessage: 'Last name',
    },
    firstName: {
        id: scope + 'firstName',
        defaultMessage: 'First name',
    },
    searchMatchesXofY: {
        id: scope + 'searchMatchesXofY',
        defaultMessage: 'Your current search matches {x} of {y} users',
    },
    changingExtraMobile: {
        id: scope + 'changingExtraMobile',
        defaultMessage: 'You are changing the extra mobile mark for this user',
    },
    changingExtraMobileText: {
        id: scope + 'changingExtraMobileText',
        defaultMessage:
            'Do you want to proceed changing the mobility status for this user?',
    },
    onboardingReminder: {
        id: scope + 'onboardingReminder',
        defaultMessage: 'Onboarding reminder',
    },
    missedOnboarding: {
        id: scope + 'missedOnboarding',
        defaultMessage: 'Send missed onboarding',
    },
    sendReminder: {
        id: scope + 'sendReminder',
        defaultMessage: 'Send reminder',
    },
    zoomLink: {
        id: scope + 'zoomLink',
        defaultMessage: 'Zoom link',
    },
    zoomPass: {
        id: scope + 'zoomPass',
        defaultMessage: 'Zoom password',
    },
    onboardingReminderSendTitle: {
        id: scope + 'onboardingReminderSendTitle',
        defaultMessage:
            'You are about to send a notification regarding the onboarding to a user.',
    },
    onboardingReminderSendMessage: {
        id: scope + 'onboardingReminderSendMessage',
        defaultMessage:
            'Send a missed onboarding notification or enter all meeting details and send a reminder. The notification will be send to {mail}',
    },
    deleteAccountWarning: {
        id: scope + 'deleteAccountWarning',
        defaultMessage: 'You are about to delete this Account',
    },
    deleteAccountWarningText: {
        id: scope + 'deleteAccountWarningText',
        defaultMessage:
            'This action will be completed at the first of the next month, 1 month from now',
    },
    mail: {
        id: scope + 'mail',
        defaultMessage: 'E-Mail',
    },
    region: {
        id: scope + 'region',
        defaultMessage: 'Region',
    },
    regionPlaceholder: {
        id: scope + 'regionPlaceholder',
        defaultMessage: 'Region',
    },
    searchingForIdWarning: {
        id: scope + 'searchingForIdWarning',
        defaultMessage:
            'If you enter a ID it must be an exact match. All other filters will be disabled',
    },
    deleteIssued: {
        id: scope + 'deleteIssued',
        defaultMessage: 'Delete issued',
    },
});
