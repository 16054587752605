import { defineMessages } from 'react-intl';

const scope = 'con.availability.';

export const availabilityMessages = defineMessages({
    repeats: {
        id: scope + 'repeats',
        defaultMessage: 'Repeats {x} weeks',
    },
    availableFromWeek: {
        id: scope + 'availableFromWeek',
        defaultMessage: 'Availability starts week {weekNumber}',
    },
    availableFromTo: {
        id: scope + 'availableFromTo',
        defaultMessage: 'Available from {fromDate} until {toDate}',
    },
    request: {
        id: scope + 'request',
        defaultMessage: 'Request',
    },
    createRequest: {
        id: scope + 'createRequest',
        defaultMessage: 'Create request',
    },
    makeSureYouAreAvailable: {
        id: scope + 'makeSureYouAreAvailable',
        defaultMessage: 'Please make sure you are available',
    },
    makeSureYouAreAvailableText: {
        id: scope + 'makeSureYouAreAvailableText',
        defaultMessage:
            'You are about to initiate a contract negotiation. Please make sure you are still available for the requested period',
    },
    iAmAvailable: {
        id: scope + 'iAmAvailable',
        defaultMessage: 'I am available',
    },
    makeSureYouHaveAvailableTalent: {
        id: scope + 'makeSureYouHaveAvailableTalent',
        defaultMessage: 'Please make sure your talent is still available',
    },
    makeSureYouHaveAvailableTalentText: {
        id: scope + 'makeSureYouHaveAvailableTalentText',
        defaultMessage:
            'You are about to initiate a contract negotiation. Please make sure your talent is still available for the requested period',
    },
    iHaveAvailableTalents: {
        id: scope + 'iHaveAvailableTalents',
        defaultMessage: 'I have available talents',
    },
    rejectRequest: {
        id: scope + 'rejectRequest',
        defaultMessage: 'Are you sure you want to reject this request?',
    },
    rejectRequestText: {
        id: scope + 'rejectRequestText',
        defaultMessage:
            'The workplace will not be able to send you another request for this talent afterwards. If you wish to adjust the offered time period, please accept the request and you will then be able to adjust it during the following negotiation.',
    },
    notAvailableOn: {
        id: scope + 'notAvailableOn',
        defaultMessage: 'Excluding these dates:',
    },
    noRequests: {
        id: scope + 'noRequests',
        defaultMessage:
            'You currently have no talent requests. Make sure you have set the availabilities for your talent to proactively receive talent requests from employers.',
    },
    configureTalentAvailabilities: {
        id: scope + 'configureTalentAvailabilities',
        defaultMessage: 'Configure talent availabilities',
    },
    openContract: {
        id: scope + 'openContract',
        defaultMessage: 'View contract',
    },
    viewNegotiation: {
        id: scope + 'viewNegotiation',
        defaultMessage: 'View negotiation',
    },
    rejected: {
        id: scope + 'rejected',
        defaultMessage: 'Rejected',
    },
    createdRequest: {
        id: scope + 'createdRequest',
        defaultMessage: 'Created request',
    },
    createdRequestText: {
        id: scope + 'createdRequestText',
        defaultMessage: 'You will receive a notification once it got accepted',
    },
    selectAWorkplace: {
        id: scope + 'selectAWorkplace',
        defaultMessage:
            'Please select the workplace you request this talent for.',
    },
    alreadyRequested: {
        id: scope + 'alreadyRequested',
        defaultMessage: 'You already requested this talent',
    },
});
