import React, { FC } from 'react';
import { CText, FileInput } from '../../../../components';
import {
    ECollections,
    EGeneralFiles,
    EWorkplaceFiles,
} from '../../../../enums';
import { MWorkplace } from '../../../../models';
import { workplaceMessages } from '../../workplace.messages';
/**
 * section for documents of workplace
 */
export const WorkplaceDocuments: FC<{
    workplace: MWorkplace;
    onChange: (next: Partial<MWorkplace>) => void;
}> = ({ workplace }) => {
    return (
        <>
            <CText secondaryHeadline message={workplaceMessages.documents} />
            <FileInput
                collection={`${ECollections.workplaces}/${workplace.documentId}/${ECollections.files}`}
                directory={`${ECollections.workplaces}/${workplace.documentId}`}
                fileTypes={[
                    ...Object.values(EWorkplaceFiles),
                    ...Object.values(EGeneralFiles),
                ]}
                defaultType={EWorkplaceFiles.locationPlan}
            />
        </>
    );
};
