import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import { useFireBase } from '../../utilities/firebase';
import { useStyle, useTheme } from '../../utilities/styles';
import { CButton, CIcon, CText } from '../elements';
import { Spinner } from '../Spinner/Spinner';
import { IDocumentLocationProps } from './IDocumentLocationProps';

import '../PDFViewer/PDFViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DocumentLocationPicker: FC<IDocumentLocationProps> = ({
    filename,
    position,
    onPosition,
}) => {
    // global state
    const style = useStyle();
    const { theme } = useTheme();
    const { userData, getFile } = useFireBase();
    // local state
    const [data, setData] = useState<any>();
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [canvasDims, setCanvasDims] = useState({ x: 0, y: 0 });
    // const [documentHeight, setDocumentHeight] = useState(0);
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (userData.documentId) {
            getFile(filename).then((res) => {
                setData(res);
            });
        }
    }, [userData, filename]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) {
            return;
        }
        // @ts-ignore
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvasDims.x, canvasDims.y);

        if (
            !position ||
            position.filename !== filename ||
            isNaN(position.page as any) ||
            position.page !== pageNumber
        )
            return;

        context.fillStyle = theme.accentColor;
        const adjustedY = canvasDims.y - (position.y || 0);
        const adjustedX = position.x || 0;
        context.fillRect(adjustedX + 5, adjustedY + 5, 100, 5);
        context.fillRect(adjustedX + 5, adjustedY + 5, 5, -40);
        context.fillRect(adjustedX + 5, adjustedY - 40, 100, 5);
        context.fillRect(adjustedX + 100, adjustedY + 5, 5, -40);
    }, [position, filename, data, canvasRef, canvasDims, theme, pageNumber]);

    return (
        <View style={{ alignItems: 'center', flex: 1 }}>
            <View
                onLayout={(event) => {
                    const { width, height } = event.nativeEvent.layout;
                    setCanvasDims({ x: width, y: height });
                }}
                style={{ maxWidth: '100%' }}
            >
                <Document
                    file={data}
                    onLoadSuccess={(sE) => {
                        setNumPages(sE.numPages);
                    }}
                    onLoadError={(e: any) => {
                        console.error(e);
                    }}
                    error={() => (
                        <View
                            style={[
                                style.card,
                                { paddingVertical: 50 },
                                style.centeredItems,
                            ]}
                        >
                            <CIcon
                                icon={'close'}
                                tint={theme.errorColor}
                                size={150}
                            />
                            <CText message={'Error loading PDF'} />
                        </View>
                    )}
                    loading={<Spinner />}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </View>
            <canvas
                ref={canvasRef}
                height={canvasDims.y}
                width={canvasDims.x}
                style={{ position: 'absolute' }}
                onClick={(event) => {
                    const nativeEvent = event.nativeEvent as any;
                    const pos = {
                        x: nativeEvent.offsetX - 50,
                        y: canvasDims.y - (nativeEvent.offsetY + 25),
                        page: pageNumber,
                    };
                    if (pos.x < 0) {
                        pos.x = 0;
                    }
                    if (pos.y < 0) {
                        pos.y = 0;
                    }
                    onPosition(pos);
                }}
            />
            {pageNumber > 0 && (
                <View
                    style={{
                        marginTop: -50,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingHorizontal: 20,
                    }}
                >
                    <CButton
                        icon="chevronLeft"
                        onPress={() => {
                            setPageNumber(pageNumber - 1);
                        }}
                        minor
                        disabled={pageNumber <= 1}
                    />
                    <CText>
                        {pageNumber} / {numPages}
                    </CText>
                    <CButton
                        icon="chevronRight"
                        onPress={() => {
                            setPageNumber(pageNumber + 1);
                        }}
                        minor
                        disabled={numPages <= pageNumber}
                    />
                </View>
            )}
        </View>
    );
};
