import { defineMessages } from 'react-intl';

const scope = 'con.sign.';

export const signMessages = defineMessages({
    fileLockedByCorrespondent: {
        id: scope + 'fileLockedByCorrespondent',
        defaultMessage:
            'File ist currently in use from your correspondent. When they finish you will be able to sign yourself.',
    },
    exposeSignatureExplanation: {
        id: scope + 'exposeSignatureExplanation',
        defaultMessage:
            'By clicking the following button you can invite someone who is not registered to sign this envelope.',
    },
    exposeSignature: {
        id: scope + 'exposeSignature',
        defaultMessage:
            'Do you want to expose this signature for unverified usage?',
    },
    exposeSignatureText: {
        id: scope + 'exposeSignatureText',
        defaultMessage:
            'Anyone who gets access to this link will be able to sign unqualified in your name.',
    },
    expose: {
        id: scope + 'expose',
        defaultMessage: 'Expose and send mail',
    },
    uploadSignedDocument: {
        id: scope + 'uploadSignedDocument',
        defaultMessage: 'Upload physically signed document',
    },
    multipleWaysToSign: {
        id: scope + 'multipleWaysToSign',
        defaultMessage: 'You can sign in multiple ways',
    },
    signInBrowser: {
        id: scope + 'signInBrowser',
        defaultMessage:
            'After reading the contract, you can digitally sign directly in the signature area below using your cursor',
    },
    signInMobile: {
        id: scope + 'signInMobile',
        defaultMessage:
            'You can digitally sign on your mobile phone using the QR code below.',
    },
    signInReality: {
        id: scope + 'signInReality',
        defaultMessage:
            'You can download the document above and upload a signed copy below.',
    },
    couldNotLoad: {
        id: scope + 'couldNotLoad',
        defaultMessage: 'Could not load the envelope for signature',
    },
});
