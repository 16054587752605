import React, { FC, useContext, useEffect, useState } from 'react';
import { CButton, CText } from '../../../components';
import { useStyle } from '../../../utilities/styles';
import { Linking, View } from 'react-native';
import { useFormat } from '../../../utilities/intl';
import { CCheckBox } from '../../../components';
import { profileMessages } from '../../Profile/Profile.messages';
import { generalMessages } from '../../../utilities/messages';
import { ProfileContext } from '../../Profile/Profile.context';
import { useEnvironment } from '../../../utilities/contexts';
import { EEnvironment } from '../../../enums';
/**
 * profile section for basic user informations.
 * all human usertypes have access
 * @param param0 props
 * @returns component
 */
export const TermsAndConditions: FC = () => {
    // global state
    const format = useFormat();
    const style = useStyle();
    const { environment } = useEnvironment();
    // context
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    // local state
    const [canConfirmAgb, setCanConfirmAgb] = useState(curData.readAGB);
    /**
     * effect to set ability to set agb
     */
    useEffect(() => {
        setCanConfirmAgb(curData.readAGB);
    }, [curData]);
    /**
     * effect to set validity
     */
    useEffect(() => {
        const validity = !curData.readAGB || !curData.agreeDataShare;

        onValidityChange(
            validity ? format(generalMessages.somethingWrong) : '',
        );
    }, [curData]);
    /**
     * render
     */
    return (
        <>
            <View style={style.verticalPadded}>
                <CCheckBox
                    cy={'agree-data-share'}
                    checked={curData.agreeDataShare}
                    onCheckedChanged={(agreeDataShare) =>
                        onChange({ agreeDataShare })
                    }
                    title={format(profileMessages.bankDataApproval, {
                        platform: environment,
                    })}
                />
                {!curData.agreeDataShare && (
                    <CText style={style.error}>
                        {format(profileMessages.agreeDataShare)}
                    </CText>
                )}
            </View>
            <View style={style.verticalPadded}>
                <View style={{ marginRight: 'auto' }}>
                    <CButton
                        cy={'open-agb'}
                        title={format(profileMessages.viewAgb)}
                        onPress={() => {
                            const agbUrl =
                                environment === EEnvironment.Lieblingsjob
                                    ? 'https://www.lieblingsjob.de/agb'
                                    : environment === EEnvironment.TimePloyees
                                    ? 'https://www.timeployees.de/agb'
                                    : 'https://www.situsdocs.de/agb';
                            Linking.openURL(agbUrl);
                            setCanConfirmAgb(true);
                        }}
                        minor
                    />
                </View>
                <CCheckBox
                    cy={'agree-agb'}
                    checked={curData.readAGB}
                    onCheckedChanged={(readAGB) => onChange({ readAGB })}
                    title={format(profileMessages.readAGB)}
                    disabled={!canConfirmAgb}
                />
                {!canConfirmAgb ? (
                    <CText style={style.error}>
                        {format(profileMessages.readAGBFirst)}
                    </CText>
                ) : (
                    !curData.readAGB && (
                        <CText style={style.error}>
                            {format(profileMessages.acceptAGB)}
                        </CText>
                    )
                )}
            </View>
            <View style={style.verticalPadded}>
                <CCheckBox
                    cy={'agree-marketing'}
                    checked={curData.marketingMail}
                    onCheckedChanged={(marketingMail) =>
                        onChange({ marketingMail })
                    }
                    title={format(profileMessages.marketingMail)}
                />
            </View>
        </>
    );
};
