import React, { FC } from 'react';
import { View } from 'react-native';
import { CLabel } from '../../../components';
import { MJob } from '../../../models';
import { capitalizeAll, useFormat } from '../../../utilities/intl';
import { useStyle, useTheme } from '../../../utilities/styles';
import { ExpandableCard } from '../../../components/ExpandableCard';
import { EJobStatus } from '../../../enums';
import { jobApplicationMessages } from '../JobApplication.messages';
import { jobMessages } from '../../Job/job.messages';
import { useJobApplicationRowState } from './useJobApplicationRowState';
import { useNavigate } from '../../../utilities/routing';
import { JobTitle } from '../../Job/components/JobTitle';
import { JobRowSubHead } from '../../Job/List/components/JobRow/components/JobRowSubHead';

interface IJobApplicationRowProps {
    job: MJob;
}

export const JobApplicationRow: FC<IJobApplicationRowProps> = ({ job }) => {
    // global state
    const style = useStyle();
    const format = useFormat();
    const navigate = useNavigate();
    const { theme } = useTheme();
    // local state
    const { memoizedMultiButton, loading, applications, contracts, workplace } =
        useJobApplicationRowState(job);

    return (
        <ExpandableCard
            cy={'application-for-' + job.documentId}
            head={
                <>
                    <View style={{ flex: 1 }}>
                        <JobTitle job={job} />
                    </View>
                    <View>
                        <View style={[style.horizontal, style.centeredItems]}>
                            {!loading &&
                                (job.status === EJobStatus.public ||
                                job.status === EJobStatus.private ? (
                                    <CLabel
                                        checked
                                        color={theme.accentColor}
                                        title={`${
                                            applications.length
                                        } ${capitalizeAll(
                                            format(
                                                jobApplicationMessages.applicants,
                                            ),
                                        )}`}
                                    />
                                ) : job.status === EJobStatus.draft ? (
                                    <CLabel
                                        checked
                                        color={theme.accentColor}
                                        title={jobMessages[EJobStatus.draft]}
                                    />
                                ) : job.status === EJobStatus.filled ? (
                                    <CLabel
                                        checked
                                        color={theme.accentColor}
                                        title={`${
                                            contracts.length
                                        } ${capitalizeAll(
                                            format(
                                                jobApplicationMessages.contracts,
                                            ),
                                        )}`}
                                    />
                                ) : undefined)}
                            {memoizedMultiButton}
                        </View>
                    </View>
                </>
            }
            subHead={<JobRowSubHead job={job} workplace={workplace} />}
            // body={}
            // customExpand={expand}
            customOnClick={(n) =>
                navigate('/japplication/view/' + job.documentId)
            }
        />
    );
};
