import React, { FC, useContext, useEffect } from 'react';
import { useFormat } from '../../../utilities/intl';
import { CPicker, InfoBox } from '../../../components';
import { ProfileContext } from '../../Profile/Profile.context';
import { profileMessages } from '../../Profile/Profile.messages';
import { EField } from '../../../enums';
import { fieldMessages } from '../../../utilities/messages';
import { onboardingMessages } from '../Onboarding.messages';

export const FieldInfo: FC = () => {
    const format = useFormat();
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);
    /**
     * set validity
     */
    useEffect(() => {
        onValidityChange('');
    }, [curData]);

    return (
        <>
            <InfoBox
                message={onboardingMessages.restrictiveFields}
                type="info"
            />
            <CPicker
                title={profileMessages.restrictiveFields}
                placeholder={profileMessages.restrictiveFieldsPlaceholder}
                multiValues={curData.fields}
                values={Object.values(EField)
                    .filter((v) => v !== EField.undefined)
                    .map((field) => ({
                        value: field,
                        label: fieldMessages[field],
                    }))}
                onChange={() => {
                    // is always multichanging
                }}
                onChangeMulti={(fields) => onChange({ fields })}
            />
        </>
    );
};
