import React, { FC, useContext, useEffect } from 'react';
import { useFormat } from '../../../utilities/intl';
import { paymentIntervalMessages } from '../../../utilities/messages';
import { Radio } from '../../../components';
import { CImage } from '../../../components/elements/CImage';
import { ProfileContext } from '../Profile.context';

export const ProfileSectionPayment: FC = () => {
    const format = useFormat();
    const { curData, onChange, onValidityChange } = useContext(ProfileContext);

    useEffect(() => {
        onValidityChange('');
    }, [curData]);

    return (
        <>
            <CImage image="payment" />
            <Radio
                cy={'payment'}
                values={[
                    {
                        label: format(paymentIntervalMessages.weekly),
                        value: 'weekly',
                    },
                    {
                        label: format(paymentIntervalMessages.monthly),
                        value: 'monthly',
                    },
                ]}
                value={curData.paymentInterval}
                onChange={(paymentInterval) => onChange({ paymentInterval })}
            />
        </>
    );
};
