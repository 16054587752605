import { createContext } from 'react';
import { MUserData } from '../../models';

export const ProfileContext = createContext(
    {} as {
        onChange: (next: Partial<MUserData>) => void;
        onValidityChange: (issue: string, key?: string) => void;
        curData: MUserData;
        prevData: MUserData;
        onboarding?: boolean;
        save?: () => void;
    },
);
