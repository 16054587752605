import React, { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { MRequest } from '../../../../models';
import { RequestRow } from './components/RequestRow';
import { useFireBase } from '../../../../utilities/firebase';
import { ECollections, EUserType } from '../../../../enums';
import { IFilter } from '../../../../utilities/firebase/store';
import { CButton, CImage, CText } from '../../../../components';
import { generalMessages } from '../../../../utilities/messages';
import { useStyle } from '../../../../utilities/styles';
import { ScrollProvider } from '../../../../utilities/contexts';
import { isPeasant } from '../../../../utilities/auth';
import { availabilityMessages } from '../../availability.messages';
import { useNavigate } from '../../../../utilities/routing';

export const RequestList: FC = () => {
    const style = useStyle();
    const navigate = useNavigate();
    const { getDataIndex, userData, userAgencies } = useFireBase();
    const [requests, setRequests] = useState<MRequest[]>([]);
    /**
     * effect to load requests
     */
    useEffect(() => {
        const filter: IFilter[] = [];
        if (userAgencies.length) {
            filter.push({
                field: 'agencyId',
                value: userAgencies.map((a) => a.documentId),
                operator: 'in',
            });
        } else if (isPeasant(userData)) {
            filter.push({ field: 'userId', value: userData.documentId });
        }
        getDataIndex(ECollections.requests, {
            filter,
        }).then((rs) => {
            setRequests((rs as MRequest[]).map((r) => new MRequest(r)));
        });
    }, [userAgencies]);
    /**
     * render
     */
    return (
        <ScrollProvider style={style.paddedScrollableMainView}>
            <View style={[style.card, { zIndex: 100 }]}>
                <CText
                    bold
                    headline
                    message={
                        userData.type === EUserType.user
                            ? generalMessages.requests
                            : generalMessages.talentRequests
                    }
                />
            </View>
            {requests.map((r) => (
                <RequestRow key={r.documentId} request={r} />
            ))}
            {!requests.length && (
                <View style={[style.card, style.centeredItems]}>
                    <CImage image={'apply'} />
                    <CText
                        centered
                        headline
                        message={availabilityMessages.noRequests}
                    />
                    <CButton
                        title={
                            availabilityMessages.configureTalentAvailabilities
                        }
                        onPress={() => navigate('/profUser')}
                    />
                </View>
            )}
        </ScrollProvider>
    );
};
