import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '../../../utilities/styles';
import {
    useLocation,
    useSecureNavigate,
    useParams,
    useSearchParams,
} from '../../../utilities/routing';
import { useFireBase } from '../../../utilities/firebase';
import { MJob } from '../../../models/MJob';
import { ECollections, EJobStatus } from '../../../enums';
import { useFormat } from '../../../utilities/intl';
import { jobMessages } from '../job.messages';
import { actionMessages } from '../../../utilities/messages/action.messages';
import { MAddress, MWorkplace } from '../../../models';
import {
    useAdminDialog,
    useDialog,
    useUnsavedChangesDialog,
} from '../../../utilities/dialog';
import { day, week } from '../../../utilities/functions';
import { useEnvironment } from '../../../utilities/contexts';
import { isSuperUser } from '../../../utilities/auth';
import { useLock } from '../../../utilities/hooks';

export const useJobEditState = () => {
    // global state
    const { theme } = useTheme();
    const { secureNavigate, setNavigationLock } = useSecureNavigate();
    const location = useLocation();
    const dialog = useDialog();
    const { lock } = useLock();

    const adminDialog = useAdminDialog();
    const { id } = useParams<{ id: string }>();
    const [searchparams] = useSearchParams();
    const { put, post, getDataById, getDataIndex, callFunction, userData } =
        useFireBase();
    const format = useFormat();
    const { environment } = useEnvironment();
    // local state
    const [job, setJob] = useState(
        new MJob({
            from: Date.now(),
            createdOn: Date.now(),
            author: userData.documentId || '',
        }),
    );
    const [prevJob, setPrevJob] = useState<MJob>();

    const [currentHospital, setCurHospital] = useState<MWorkplace>();

    const [loading, setLoading] = useState(true);
    /**
     * memoiued value if user is currently creating a new job
     */
    const isCreating = useMemo(
        () =>
            id === 'new' ||
            location.pathname.includes('duplicate') ||
            location.pathname.includes('publish') ||
            location.pathname.includes('draft'),
        [id, location],
    );
    /**
     * memoized value if any value has changed
     */
    const unsavedChanges = useMemo(
        () => !prevJob || JSON.stringify(prevJob) !== JSON.stringify(job),
        [prevJob, job],
    );
    /**
     * validity state. key value updating
     */
    const [curValidity, setCurValidity] = useState<any>({});
    /**
     * callback to update validity
     */
    const updateValidity = useCallback((next: any) => {
        setCurValidity((prev: any) => ({ ...prev, ...next }));
    }, []);
    /**
     * memoized invalidity
     */
    const isInvalid = useMemo(
        () =>
            !!Object.keys(curValidity).filter((key) => !!curValidity[key])
                .length,
        [curValidity],
    );
    /**
     * handle changes
     */
    const onChange = useCallback((next: Partial<MJob>) => {
        setJob((prev) => {
            return { ...prev, ...next };
        });
    }, []);
    /**
     * callback to handle location (workplace) change
     */
    const onChangeLocation = useCallback((next: MWorkplace) => {
        onChange({
            workPlaceId: next.documentId,
            location: next.address.zipCode.substring(0, 2),
            region: next.region,
            agencies: next.agencies,
        });
        setCurHospital(next);
    }, []);
    /**
     * move current job to archive and delete it afterwards
     */
    const handleDelete = useCallback(() => {
        dialog({
            title: format(jobMessages.deleteConfirmTitle),
            message: format(jobMessages.deleteConfirmText),
            cancelButton: {
                text: format(actionMessages.back),
            },
            buttons: [
                {
                    text: format(actionMessages.delete),
                    onPress: async () => {
                        const unlock = lock();
                        await callFunction('closeJob', {
                            jobId: job.documentId,
                            status: EJobStatus.disabled,
                        });
                        unlock();
                        secureNavigate('/job', { force: true });
                    },
                    color: theme.errorColor,
                },
            ],
            icon: 'warning',
        });
    }, [job]);
    /**
     * save handler
     */
    const handleSave = useCallback(
        async (skipNavigate?: boolean) => {
            if (isSuperUser(userData) && !(await adminDialog())) return;
            let fullAddress: MAddress | undefined = undefined;

            if (job.useFullAddress && currentHospital) {
                fullAddress = currentHospital.address;
            }
            if (isCreating && !isSuperUser(userData)) {
                const prevJobCountOver0 = await getDataIndex(
                    ECollections.jobs,
                    {
                        filter: [
                            {
                                field: 'author',
                                value: userData.documentId,
                            },
                        ],
                        limit: 1,
                        getLength: true,
                    },
                );
                const next: MJob = new MJob({
                    ...job,
                    author: userData.documentId || '',
                    createdOn: Date.now(),
                    from: job.fromNowUntillUndefined ? Date.now() : job.from,
                    to: job.fromNowUntillUndefined ? Date.now() + week : job.to,
                    // clear to issue recalculation
                    activeDates: undefined,
                    activeMonths: undefined,
                    fullAddress,
                    workplaceUsers: currentHospital?.users,
                });
                const postResult = await post(ECollections.jobs, next);
                if (!prevJobCountOver0 && postResult) {
                    const share = await dialog({
                        title: jobMessages.firstJobCreated,
                        message: format(jobMessages.firstJobCreatedText, {
                            platform: environment,
                        }),
                        buttons: [
                            {
                                text: jobMessages.inviteNow,
                            },
                        ],
                        cancelButton: {
                            text: jobMessages.inviteLater,
                        },
                        icon: 'rocketman',
                    });
                    if (share) {
                        await dialog({
                            title: jobMessages.shareJob,
                            message: jobMessages.shareJobText,
                            textInputs: [
                                {
                                    title: jobMessages.recipientPlaceholder,
                                    id: 'mail',
                                },
                            ],
                            buttons: [
                                {
                                    text: actionMessages.share,
                                    onPress: (inputs) => {
                                        const mail =
                                            inputs?.find((v) => v.id === 'mail')
                                                ?.value || '';
                                        callFunction('sendJobMail', {
                                            jobId: postResult.id,
                                            mail,
                                        });
                                    },
                                },
                            ],
                            cancelButton: {
                                text: actionMessages.cancel,
                            },
                        });
                    }
                }
                if (!skipNavigate) {
                    /**
                     * navigate to japp view after completion
                     */
                    secureNavigate(
                        '/japplication/view/' + postResult?.id + '?fl=true',
                        {
                            force: true,
                        },
                    );
                }
            } else if (id) {
                const next: MJob = new MJob({
                    ...job,
                    // clear to issue recalculation
                    activeDates: [],
                    activeMonths: [],
                    fullAddress,
                    workplaceUsers: currentHospital?.users,
                });
                await put(ECollections.jobs, id, next);
                if (!skipNavigate) {
                    /**
                     * navigate to japp view after completion
                     */
                    secureNavigate('/japplication/view/' + id + '?fl=true', {
                        force: true,
                    });
                }
            } else {
                throw 'no id for this job';
            }
        },
        [job, isCreating, userData, currentHospital],
    );
    const unsavedChangesDialog = useUnsavedChangesDialog(handleSave);
    /**
     * ask for discard of unsaved changes
     */
    const navigationLock = useCallback(async () => {
        const locking = unsavedChanges && !(await unsavedChangesDialog());
        return locking;
    }, [unsavedChanges, unsavedChangesDialog]);
    /**
     * callback to set location validity
     */
    useEffect(() => {
        updateValidity({
            workplace: currentHospital
                ? undefined
                : format(jobMessages.noWorkplaceSelected),
        });
    }, [currentHospital, updateValidity]);
    /**
     * register lock as current navigationlock
     */
    useEffect(() => setNavigationLock(navigationLock), [navigationLock]);
    /**
     * effect to load prev data
     */
    useEffect(() => {
        if (id && id !== 'new') {
            getDataById(ECollections.jobs, id).then((result) => {
                const next = new MJob(result);
                if (location.pathname.includes('publish')) {
                    next.status = EJobStatus.public;
                } else if (location.pathname.includes('draft')) {
                    next.status = EJobStatus.draft;
                } else if (location.pathname.includes('duplicate')) {
                    if (next.status === EJobStatus.filled) {
                        next.status = EJobStatus.public;
                    }
                    next.documentId = '';
                    next.id = 0;
                }
                setJob(next);
                if (!location.pathname.includes('duplicate')) {
                    setPrevJob(next);
                }
                setLoading(false);
                if (next.workPlaceId) {
                    getDataById(ECollections.workplaces, next.workPlaceId).then(
                        (wpr) => {
                            setCurHospital(new MWorkplace(wpr));
                        },
                    );
                }
            });
        } else {
            const from = searchparams.get('from') || Date.now() + day;
            if (from && !isNaN(+from)) {
                onChange({ from: +from, to: +from + day });
            }
            setLoading(false);
        }
    }, [id, location]);
    /**
     * expose state
     */
    return {
        handleDelete,
        loading,
        currentHospital,
        isCreating,
        onChange,
        job,
        updateValidity,
        fromNow: job.fromNowUntillUndefined,
        setFromNow: (next: boolean) =>
            onChange({ fromNowUntillUndefined: next }),
        curValidity,
        onChangeLocation,
        handleSave,
        isInvalid,
        unsavedChanges,
    };
};
