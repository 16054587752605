import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
    MChat,
    MProfessionalProfile,
    MRequest,
    MWorkplace,
} from '../../../../../models';
import { useStyle, useTheme } from '../../../../../utilities/styles';
import {
    CButton,
    CIcon,
    CText,
    ProfilePicture,
    StarDisplay,
} from '../../../../../components';
import { useFireBase } from '../../../../../utilities/firebase';
import { EApplicationStatus, ECollections } from '../../../../../enums';
import { useDialog } from '../../../../../utilities/dialog';
import { availabilityMessages } from '../../../availability.messages';
import {
    actionMessages,
    dayMessages,
    generalMessages,
} from '../../../../../utilities/messages';
import { isAgencyUser } from '../../../../../utilities/auth';
import {
    useNavigate,
    useSecureNavigate,
} from '../../../../../utilities/routing';
import {
    convertNumberToCurrencyString,
    timeString,
} from '../../../../../utilities/functions';
import { profProfileMessages } from '../../../../ProfProfile/ProfProfile.messages';
import { useFormat } from '../../../../../utilities/intl';
import { useEnvironment } from '../../../../../utilities/contexts';
import { jobMessages } from '../../../../Job/job.messages';
import { useLock } from '../../../../../utilities/hooks';

export const RequestRow: FC<{ request: MRequest }> = ({ request }) => {
    const style = useStyle();
    const dialog = useDialog();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const format = useFormat();
    const { region } = useEnvironment();
    const { lock } = useLock();
    const { secureNavigate } = useSecureNavigate();
    const { getDataById, getDataIndex, callFunction, userData } = useFireBase();
    const [workplace, setWorkplace] = useState<MWorkplace>();
    const [talent, setTalent] = useState<MProfessionalProfile>();
    const [chat, setChat] = useState<MChat>();

    /**
     * memoized talentprofile name
     */
    const name = useMemo(() => {
        if (!talent) return '';
        if (talent.firstName && talent.lastName) {
            return `${talent.firstName} ${talent.lastName}`;
        } else if (talent.lastName) {
            return talent.lastName;
        } else {
            return '';
        }
    }, [talent]);
    /**
     * memoized hourly rate with double digit fix applied
     */
    const hourlyRate = useMemo(() => {
        if (!talent) return 0;
        const next = talent.wage.wage + talent.wage.regularWage;
        /**
         * this addition can fuck up so fix it
         */
        return Math.round(next * 100) / 100;
    }, [talent]);
    /**
     * callback to handle accept
     */
    const handleAccept = useCallback(async () => {
        /**
         * get confirmation of availability
         */
        const availabilityConfirmation = isAgencyUser(userData)
            ? await dialog({
                  icon: 'warning',
                  title: availabilityMessages.makeSureYouHaveAvailableTalent,
                  message:
                      availabilityMessages.makeSureYouHaveAvailableTalentText,
                  buttons: [
                      {
                          text: availabilityMessages.iHaveAvailableTalents,
                      },
                  ],
                  cancelButton: {
                      text: actionMessages.cancel,
                  },
                  verticalButtons: true,
              })
            : await dialog({
                  icon: 'warning',
                  title: availabilityMessages.makeSureYouAreAvailable,
                  message: availabilityMessages.makeSureYouAreAvailableText,
                  buttons: [
                      {
                          text: availabilityMessages.iAmAvailable,
                      },
                  ],
                  cancelButton: {
                      text: actionMessages.cancel,
                  },
                  verticalButtons: true,
              });
        if (!availabilityConfirmation) return;
        const unlock = lock();
        /**
         * call function to do all the work
         */
        const contractId = await callFunction('changeRequestStatus', {
            requestId: request.documentId,
            nextStatus: EApplicationStatus.negotiating,
        });
        unlock();
        /**
         * navigate to negotiation (not the best reaction since first nego step if for other side)
         * TODO: react better
         */
        if (contractId) {
            navigate('/contract/negotiation/' + contractId);
        }
    }, [request, userData]);
    /**
     * callback to handle rejection
     */
    const handleReject = useCallback(async () => {
        const confirm = await dialog({
            icon: 'question',
            title: availabilityMessages.rejectRequest,
            message: availabilityMessages.rejectRequestText,
            buttons: [{ text: actionMessages.reject }],
            cancelButton: { text: actionMessages.cancel },
        });
        if (!confirm) return;
        /**
         * call function to do all the work
         * TODO: react
         */
        await callFunction('changeRequestStatus', {
            requestId: request.documentId,
            nextStatus: EApplicationStatus.rejected,
        });
    }, []);
    /**
     * effect to load workplace info
     */
    useEffect(() => {
        getDataById(ECollections.publicWorkplaces, request.workplaceId).then(
            (w) => {
                setWorkplace(new MWorkplace(w));
            },
        );
        if (request.profileId) {
            getDataById(ECollections.profProfiles, request.profileId).then(
                (t) => setTalent(new MProfessionalProfile(t)),
            );
        }
    }, [request]);
    /**
     * effect to load talent chat for registered user
     */
    useEffect(() => {
        if (!talent) return;
        getDataIndex(ECollections.chats, {
            filter: [
                {
                    field: 'participants',
                    operator: 'array-contains',
                    value: userData.documentId,
                },
            ],
        }).then((r) => {
            const coi = (r as MChat[]).find((c) =>
                c.participants.includes(talent.uid || ''),
            );
            if (coi) {
                setChat(new MChat(coi));
            }
        });
    }, [talent, userData]);
    /**
     * render
     */
    return (
        <View style={[style.card, style.horizontalSplit]}>
            <View style={style.flex1}>
                {talent && (
                    <View style={[style.horizontal, style.centeredItems]}>
                        <ProfilePicture data={talent} />
                        <View style={style.flex1}>
                            {!!name && <CText secondaryHeadline>{name}</CText>}
                            <View style={style.horizontal}>
                                <CButton
                                    onPress={() => {
                                        secureNavigate(
                                            '/calendar?profileId=' +
                                                talent.documentId,
                                        );
                                    }}
                                    icon={'calendar'}
                                    transparent
                                    small
                                />
                                {!!chat && (
                                    <CButton
                                        onPress={() =>
                                            secureNavigate(
                                                '/chat/' + chat.documentId,
                                            )
                                        }
                                        transparent
                                        small
                                        icon={'chat'}
                                    />
                                )}
                                {!!(talent.uid && !chat) && (
                                    <CButton
                                        onPress={() => {
                                            callFunction('openChatWithTalent', {
                                                talentId: talent.documentId,
                                                talentUid: talent.uid,
                                            }).then((chatId) => {
                                                secureNavigate(
                                                    '/chat/' + chatId,
                                                );
                                            });
                                        }}
                                        transparent
                                        small
                                        icon={'chat'}
                                    />
                                )}
                            </View>
                        </View>
                        <View style={{ alignItems: 'flex-end' }}>
                            {request.status === EApplicationStatus.open && (
                                <View style={style.horizontal}>
                                    <CButton
                                        icon="close"
                                        onPress={handleReject}
                                        minor
                                        transparent
                                        iconColor={theme.errorColor}
                                    />
                                    <CButton
                                        icon="check"
                                        onPress={handleAccept}
                                        style={{
                                            backgroundColor: theme.successColor,
                                        }}
                                    />
                                </View>
                            )}
                            {request.status === EApplicationStatus.rejected && (
                                <View
                                    style={[
                                        style.centeredItems,
                                        style.horizontal,
                                    ]}
                                >
                                    <CIcon
                                        icon="close"
                                        tint={theme.errorColor}
                                    />
                                    <CText
                                        message={availabilityMessages.rejected}
                                    />
                                </View>
                            )}
                            {request.status ===
                                EApplicationStatus.negotiating && (
                                <View style={style.horizontal}>
                                    <CButton
                                        small
                                        icon="eye"
                                        title={
                                            availabilityMessages.viewNegotiation
                                        }
                                        onPress={() =>
                                            navigate(
                                                `/contract/negotiation/${request.contractId}`,
                                            )
                                        }
                                    />
                                </View>
                            )}
                            {request.status === EApplicationStatus.accepted && (
                                <View style={style.horizontal}>
                                    <CButton
                                        small
                                        icon="eye"
                                        title={
                                            availabilityMessages.openContract
                                        }
                                        onPress={() =>
                                            navigate(
                                                `/contract/${request.contractId}`,
                                            )
                                        }
                                    />
                                </View>
                            )}
                            {hourlyRate ? (
                                <CText>
                                    {`${format(
                                        profProfileMessages.rate,
                                    )} ${convertNumberToCurrencyString(
                                        hourlyRate,
                                        region,
                                    )}`}
                                </CText>
                            ) : (
                                <CText message={profProfileMessages.noRate} />
                            )}
                        </View>
                    </View>
                )}
                <View style={style.paddedThinSeparator} />
                {workplace && (
                    <View style={[style.horizontal, style.centeredItems]}>
                        <CText secondaryHeadline message={workplace?.name} />
                        <View style={style.leftPadded} />
                        {workplace.reviewCount && (
                            <>
                                <StarDisplay stars={workplace?.averageRating} />
                                <CText message={`(${workplace.reviewCount})`} />
                            </>
                        )}
                    </View>
                )}
                {workplace && (
                    <CText
                        message={format(jobMessages.jobLocation, {
                            location: workplace.location,
                        })}
                    />
                )}
                <View style={style.paddedThinSeparator} />
                <CText message={jobMessages.fromTo} />
                <CText
                    message={`${new Date(request.from).toLocaleDateString(
                        'de',
                    )} - ${new Date(request.to).toLocaleDateString('de')}`}
                />
                <View style={style.verticalPadded}>
                    <CText message={jobMessages.workTimeSum} />
                    <CText
                        message={`${request.days.reduce(
                            (acc, d) => (acc += d.to - d.from),
                            0,
                        )} ${format(generalMessages.hours)}`}
                    />
                </View>
                <View style={style.verticalPadded}>
                    {request.days
                        .sort((a, b) => a.day - b.day)
                        .map((availability) => (
                            <View
                                key={availability.day}
                                style={style.horizontalSplit}
                            >
                                <CText
                                    message={
                                        dayMessages[
                                            Object.keys(dayMessages).filter(
                                                (key) => !key.includes('Short'),
                                            )[
                                                availability.day - 1
                                            ] as keyof typeof dayMessages
                                        ]
                                    }
                                />
                                <CText
                                    message={`${timeString(
                                        availability.from,
                                        0,
                                    )} - ${timeString(availability.to, 0)}`}
                                />
                            </View>
                        ))}
                </View>
            </View>
        </View>
    );
};
